import React from "react";
import styled from "styled-components";
import { CheckBox } from "components";

const Container = styled.div`
  background: ${(props) => props.theme.palette.gray};
  padding: 2px 6px 6px 6px;
  width: 100%;
  margin-bottom: 4px;
  border-left: 4px
    ${(props) =>
      props.active
        ? props.theme.palette.primary
        : props.theme.palette.itemsHover}
    solid;
`;

export const MetaCheckBox = ({
  children,
  className,
  value = "",
  disabled,
  onChange = () => {},
}) => {
  return (
    <Container className={className} active={value}>
      <CheckBox value={value} onChange={onChange} disabled={disabled}>
        {children}
      </CheckBox>
    </Container>
  );
};
