/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Link, ProductList, OfferList } from "components";
import { dupeOffer, dupeProduct, getAdBlockById, getCircularById, getImage, importOffers, importProducts, rejectImageMatchAPI, setDupe } from "api";
import styled from "styled-components";
import { useLocation } from "@reach/router";
import { useMutation, useQuery } from "react-query";
import { format } from "date-fns";
import { Button, Col, Divider, Image, Modal, Row, Tag, Tooltip } from "antd";
import { getCachedImage, getIncentiveInfo, setCachedImage } from "utils";
import { toast } from "react-toastify";
import { imageFallback } from "config/image";
import { IncentiveList } from "components/common/IncentiveListCommon";

const KEY_MAP = {
  bulkqty_f: "Bulk Quantity",
  newitem_f: "New Item",
  select_varieties_f: "Selected Varieties",
  bonus_card_f: "Bonus Card",
  brand_equity_f: "Brand Equity",
  coupon_f: "Coupon",
  rebate_f: "Rebate",
  inc_discount_f: "Inc Discount",
  limitedtime_f: "Limited Time",
  loyalty_f: "Loyalty",
  meal_deal_f: "Meal Deal",
  online_only_f: "Online Only",
  show_math_f: "Show Math",
  sugar_tax_f: "Sugar Tax",
  split: "Split",
  sponsored_f: "Sponsored",
  qr_code_f: "QR Code"
};

export const ImageMatchingModal = ({
  open,
  currentAdBlockData = '',
  circularName = '',
  dupe_block = '',
  onClose,
  view = 'offer',
  startTime = '',
  sizeData = [],
  onImport = () => { },
  onError = () => { },
  onSuccess = () => { }
}) => {
  const location = useLocation();
  const [confirmDupe, setConfirmDupe] = useState(false);
  const [similarCircularName, setSimilarCircularName] = useState('');
  const [productData, setProductData] = useState([]);
  const [offersData, setOffersData] = useState([]);
  const [incentiveData, setIncentiveData] = useState([]);

  const { data: { payload: currentImage } = {}, isFetching: isCurrentImageLoading } = useQuery(
    ['currentImage', currentAdBlockData.filename],
    async () => loadImage(currentAdBlockData.filename),
    {
      enabled: (Boolean(dupe_block) && !currentAdBlockData.dupe_of) && ((view === 'product' && (!currentAdBlockData.products_approved && !currentAdBlockData.product_dupe_of)) || (view === 'offer' && (!currentAdBlockData.offers_approved && !currentAdBlockData.offer_dupe_of))),
      initialData: { payload: [] },
      refetchOnWindowFocus: false, // Disable refetching on window focus
      refetchOnMount: false, // Disable refetching on component mount,
    }
  );

  const { data: { payload: similarAdBlockData }, isFetching: isSimilarAdBlockLoading } = useQuery(
    ['similarAdBlockData', dupe_block],
    async () => getAdBlockById(dupe_block),
    {
      enabled: (Boolean(dupe_block) && !currentAdBlockData.dupe_of) && ((view === 'product' && (!currentAdBlockData.products_approved && !currentAdBlockData.product_dupe_of)) || (view === 'offer' && (!currentAdBlockData.offers_approved && !currentAdBlockData.offer_dupe_of))),
      initialData: { payload: [] },
      refetchOnWindowFocus: false, // Disable refetching on window focus
      refetchOnMount: false, // Disable refetching on component mount
      onSuccess: async (data) => { await onLoad(data.payload); }
    })

  const { data: { payload: similarImage } } = useQuery(
    ['similarImage', similarAdBlockData?.filename],
    async () => loadImage(similarAdBlockData?.filename),
    {
      enabled: similarAdBlockData?.filename ? true : false,
      initialData: { payload: [] },
      // refetchOnWindowFocus: false, // Disable refetching on window focus
      // refetchOnMount: false, // Disable refetching on component mount
    }
  )

  const onLoad = async (data) => {
    const result = await getCircularById(data.circular_uuid);
    if (result.success) {
      setSimilarCircularName(
        result.payload.circ_subtype +
        "_" +
        result.payload.retailer.name +
        "_" +
        format(
          new Date(
            result.payload.circ_week_dt.replace(/-/g, "/").replace(/T.+/, "")
          ),
          "MM/dd/yyyy"
        ) +
        `_${result.payload.ext_id}` +
        `_${result.payload.circ_version ? result.payload.circ_version : 0}`
      );
    } else {
      //TODO: set error message about no circular matching
    }
    setProductData(data.products);
    setOffersData(data.offers);
    if (!data.incentive_text) return;
    const incentivesData = getIncentiveInfo(data?.incentive_text).map((incentives, index) => ({
      incentive_type: incentives.incentive_type,
      incentive_text: incentives.incentive_text,
      index, // Use the index from map instead of manually calculating it
      isValid: true,
      incentive_attach_to: 'Adblock',
      incentive_limit: incentives.limit,
      incentive_include_net_cost: incentives.net_cost,
      incentive_by_mail: incentives.by_mail,
      incentive_online_incentive: incentives.online_incentive
    }));

    setIncentiveData(incentivesData);
  }

  const loadImage = async (filename) => {
    if (!filename) return { payload: [] };
    const cachedImage = await getCachedImage(filename);
    if (cachedImage) {
      return { payload: cachedImage };
    }
    const backImg = await getImage(filename);
    if (backImg?.success) {
      await setCachedImage(filename, backImg.payload);
      return { payload: backImg.payload };
    }
    return { payload: '' };
  };


  const handleImportProduct = useMutation({
    mutationFn: (data) => importProducts(data.adblockId, data.parentId, data.startTime),
    onSuccess: (data) => {
      const products = data.payload
      onClose();
      onImport(products);
    }
  });

  const handleDupeProduct = useMutation({
    mutationFn: (data) => dupeProduct(data.adblockId, data.parentId, data.startTime),
    onSuccess: () => {
      onSuccess();
      onClose();
    },
    onError: (error) => {
      onError(error);
    }
  })

  const handleDupeOffer = useMutation({
    mutationFn: (data) => dupeOffer(data.adblockId, data.parentId, data.startTime),
    onSuccess: () => {
      onSuccess();
      onClose();
    },
    onError: (error) => {
      onError(error);
    }
  })

  const handleImportOffer = useMutation({
    mutationFn: (data) => importOffers(data.adblockId, data.parentId, data.dupe, data.startTime),
    onSuccess: (data) => {
      onClose();
      onImport(data);
    }
  });

  const handleSetCompleteDuplicate = useMutation({
    mutationFn: (data) => setDupe(data.adblockId, data.parentId, startTime),
    onSuccess: () => {
      onSuccess();
      onClose();
    },
  })

  const handleCancel = useMutation({
    mutationFn: () => {
      return rejectImageMatchAPI(currentAdBlockData.uuid, view);
    },
    onSuccess: () => {
      toast.info("Image Match Rejected", {
        position: "bottom-center", autoClose: 1000, hideProgressBar: false, closeOnClick: true,
        pauseOnHover: true, progress: undefined, theme: "dark"
      });
    },
    onError: (error) => {
      toast.error("Error Rejecting Image Match", {
        position: "bottom-center", autoClose: 1000, hideProgressBar: false, closeOnClick: true,
        pauseOnHover: true, progress: undefined, theme: "dark"
      });
    },
    onSettled: () => {
      onClose();
    }
  });

  return (
    <Modal
      open={open}
      onCancel={() => {
          handleCancel.mutate();
      }}
      title="Similar Block Identification"
      width={'100vw'}
      footer={null}
      titleFontSize={50}
      maskClosable={false}
      closeIcon={null}
      closable={false}
    >
      <Row>
        <Col
          span={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
        <Link target="_blank" href={
            location.origin
            + (
              location.pathname.includes("/product/") ? "/product/" :
                location.pathname.includes("/offer/") ? "/offer/" :
                  location.pathname.substring(0, location.pathname.indexOf("/", 1) + 1)
            ) + currentAdBlockData?.similar_circular_uuid
            + "/" + currentAdBlockData?.similar_page_uuid
            + "/" + currentAdBlockData?.similar_adblock_uuid
          }
            style={{
              textDecoration: 'none',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >

            <Divider orientation="center">Matched Block</Divider>
            <div>{similarCircularName ? similarCircularName : 'Loading'}</div>
            <ImageDiv>
              <StyledImage
                preview={false}
                src={`data:image/jpeg;base64,${similarImage}`}
                fallback={imageFallback}
              />
            </ImageDiv>
            <div style={{
              marginTop: '10px'
            }}>
              <Tag color={similarAdBlockData?.products_approved ? 'green' : 'yellow'}>{similarAdBlockData?.products_approved ? 'Product Approved' : 'Product Incomplete'}</Tag>
              <Tag color={similarAdBlockData?.offers_approved ? 'green' : 'yellow'}>{similarAdBlockData?.offers_approved ? 'Offer Approved' : 'Offer Incomplete'}</Tag>
            </div>
          </Link>
        </Col>
        <Col
          span={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Divider orientation="center">Current Block</Divider>
          <div>{circularName}</div>
          <ImageDiv>
            <Image
              src={`data:image/jpeg;base64,${currentImage}`}
              preview={false}
              fallback={imageFallback}
              style={{
                maxWidth: '300px',
              }}
            />
          </ImageDiv>
          <div style={{
            marginTop: '10px'
          }}>
            <Tag color={currentAdBlockData?.products_approved ? 'green' : 'yellow'}>{currentAdBlockData?.products_approved ? 'Product Approved' : 'Product Incomplete'}</Tag>
            <Tag color={currentAdBlockData?.offers_approved ? 'green' : 'yellow'}>{currentAdBlockData?.offers_approved ? 'Offer Approved' : 'Offer Incomplete'}</Tag>
            {currentAdBlockData?.has_children &&
              <Tooltip title="This block has children. When marked as Complete Match, all its children will also be marked as CM of the matched block." color="#101010">
                <Tag color="cyan">Has Children</Tag>
              </Tooltip>
            }
          </div>
        </Col>
      </Row>
      <Divider orientation="center">Meta Data in Matched Block</Divider>
      <div style={{ textAlign: 'center' }}>
        {Object.keys(KEY_MAP).filter((key) => similarAdBlockData[key]).length === 0 ? (
          <p>No Meta Data</p>
        ) : (
          Object.keys(KEY_MAP).filter((key) => similarAdBlockData[key]).map((key) => (
            similarAdBlockData[key] === true ? (
              <Tag key={key}>
                {KEY_MAP[key]} {KEY_MAP[key] === 'Coupon'
                  ? `: ${similarAdBlockData.coupon_amnt}` : KEY_MAP[key] === 'Rebate'
                    ? `: ${similarAdBlockData.rebate_amnt}` : ''}
              </Tag>
            ) : null
          ))
        )}
      </div>
      {view === 'product' ?
        <div>

          <Divider orientation="center">Products in Matched Block</Divider>
          <ProductList
            productsData={productData}
            width="small"
            displayOnly={true}
            sizeData={sizeData}
          />
        </div>
        : <div>
          <Divider orientation="center">Offers/Incentives in Matched Block</Divider>
          <OfferList
            offersData={offersData}
            width="small"
            displayOnly={true}
            sizeData={sizeData}
          />
          <IncentiveList
            incentiveData={incentiveData}
            displayOnly={true}
            width="small"
          />
        </div>
      }
      <div className="d-flex mt-4" style={{
        justifyContent: 'center'
      }}>
        <Button
          className="ml-2"
          color="danger" variant="solid"
          disabled={handleImportOffer.isLoading || handleImportProduct.isLoading || handleSetCompleteDuplicate.isLoading || handleDupeProduct.isLoading || handleDupeOffer.isLoading || handleCancel.isLoading}
          onClick={() => handleCancel.mutate()}
        >
          Cancel
        </Button>
        {view === 'product' ?
          <>
            <Button
              className="ml-2"
              type="default"
              disabled={handleImportOffer.isLoading || handleImportProduct.isLoading || handleSetCompleteDuplicate.isLoading || !similarAdBlockData.products_approved || handleDupeProduct.isLoading || handleDupeOffer.isLoading || handleCancel.isLoading}
              onClick={() => handleImportProduct.mutate({
                adblockId: currentAdBlockData.uuid,
                parentId: similarAdBlockData.uuid,
                startTime: startTime
              })}
            >
              Import Products (EM)
            </Button>
            <Button
              className="ml-2"
              type="default"
              disabled={handleImportOffer.isLoading || handleImportProduct.isLoading || handleSetCompleteDuplicate.isLoading || !similarAdBlockData.products_approved || handleDupeProduct.isLoading || handleDupeOffer.isLoading || handleCancel.isLoading || handleCancel.has_children}
              onClick={() => handleDupeProduct.mutate({
                adblockId: currentAdBlockData.uuid,
                parentId: similarAdBlockData.uuid,
                startTime: startTime
              })}
            >
              Duplicate Products (IM)
            </Button>
          </> :
          <>
            <Button
              type="default"
              className="ml-2"
              disabled={handleImportOffer.isLoading || handleImportProduct.isLoading || handleSetCompleteDuplicate.isLoading || !similarAdBlockData.offers_approved || handleDupeProduct.isLoading || handleDupeOffer.isLoading || handleCancel.isLoading}
              onClick={() => handleImportOffer.mutate({
                adblockId: currentAdBlockData.uuid,
                parentId: similarAdBlockData.uuid,
                startTime: startTime
              })}
            >
              Import Offers (EM)
            </Button>
            <Button
              className="ml-2"
              type="default"
              disabled={handleImportOffer.isLoading || handleImportProduct.isLoading || handleSetCompleteDuplicate.isLoading || !similarAdBlockData.offers_approved || handleDupeProduct.isLoading || handleDupeOffer.isLoading || handleCancel.isLoading || handleCancel.has_children || currentAdBlockData?.product_dupe_of}
              onClick={() => handleDupeOffer.mutate({
                adblockId: currentAdBlockData.uuid,
                parentId: similarAdBlockData.uuid,
                startTime: startTime
              })}
            >
              Duplicate Offers (IM)
            </Button>
          </>}
        <CompleteMatchButton
          className="ml-2"
          disabled={handleImportOffer.isLoading || handleImportProduct.isLoading || handleSetCompleteDuplicate.isLoading || handleDupeProduct.isLoading || handleDupeOffer.isLoading || handleCancel.isLoading}
          onClick={() => {
            if (confirmDupe) {
              handleSetCompleteDuplicate.mutate({
                adblockId: currentAdBlockData.uuid,
                parentId: similarAdBlockData.uuid
              })
            } else {
              setConfirmDupe(true);
            }
          }}
        >
          {!confirmDupe ? 'Complete Duplicate (CM)' : 'Confirm?'}
        </CompleteMatchButton>
      </div>
    </Modal>
  );
};


const CompleteMatchButton = styled(Button)`
  background-color: #c9e75d;
  border-color: #c9e75d;
  color: black;
  &:hover {
      background-color: #a0d911 !important;
      /* border-radius: 0px; */
      border-color: #a0d911 !important;
      color: black !important;
    }
`;

const ImageDiv = styled.div`
  width: 300px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #202020;  
`;

const StyledImage = styled(Image)`
  width: 100%;
  max-width: 300px;
  max-height: 350px;
  transition: 0.3s; */
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;
