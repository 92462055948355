/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { PAGE_TYPES } from "config";
import {
  PageDropDown,
  AdBlockDropDown,
  Card,
  OfferSelector,
  AdblockLocation,
  Link,
  AdblockImage,
  AdblockApproved,
  ManualEntry,
  EventsDropDown,
  QuickSelectionChoice,
  OfferItem,
  IncentiveItem,
  OfferTextBuilder,
  IncentiveTextBuilder,
  NoSuggestions,
  QuickSelection,
  MetaData,
  PageInfo,
  FlagButtonGroup,
  EmptyOfferSelector,
  Button,
} from "components";
import { INIT_OFFER_TEXT } from "config";
import { IncentiveInfo, OfferInfo, EmptyInfo } from "fragments";
import { useMainContext } from "contexts";
import { EndQueueModal, ImageMatchingModal, OfferDeletionModal } from "modals";
import { ConfirmationModal } from "modals/commons/ConfirmationModal";
import {
  getImage,
  approveOffer,
  getOfferTypesApi,
  updateAdblockSuggestions,
  skipAdblock,
  doNotEnterAdblock,
  getCircularById,
  saveCircularIncentives,
  savePageIncentives,
  rejectAdblock,
  getSimilarAdBlockOffers,
  getCurrentUserAPI,
  getAdBlockById,
  getPageCrops,
  getPageIncentive,
  getCircularIncentive,
  getProductSizesApi,
  unApproveOffer
} from "api";
import { 
  extractTextBetweenFirstBalancedParenthesis, 
  getIncentiveInfo,
  validateIncentive, 
  validateOffer, 
  setCachedImage, 
  getCachedImage, 
  offerValidationSchema 
} from "utils";
import { useInput, useModal } from "hooks";
import { useParams, navigate } from "@reach/router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { forEach, omit } from "lodash";
import useKeypress from "react-use-keypress";
import { useAuthContext } from "contexts";
import { format } from "date-fns";
import {
  OFFER_SUGGESTION_TITLE,
  INCENTIVE_SUGGESTION_TITLE,
  OFFER_INIT_SUGGESTIONS,
  OFFER_INIT_INFO,
  INCENTIVE_INIT_SUGGESTION,
  INCENTIVE_INIT_INFO,
  INCENTIVE_INFO_FIELDS,
  OFFER_INFO_FIELDS,
  INIT_OFFER_METADATA,
  EXTRA_INCENTIVE_SUGGESTIONS,
} from "config";
import { BsImageAlt } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { updateOffersBasedOnDigitalCoupon } from "utils/offers";

export const OfferPage = () => {
  const params = useParams();
  const blockRef = useRef();
  const {
    currentCircularId,
    currentPageId,
    currentAdBlockId,
    setCurrentCircularId,
    setCurrentPageId,
    setCurrentAdBlockId,
    setCurrentPage,
    setPreviousTime,
    postPageEvents,
    activeField,
    setActiveField,
    setFieldNames,
    moveNextField,
    updateActiveInfo,
    currentInfo,
    setCurrentInfo,
    setSessionId,
    activeFieldValue,
    updateInfo,
    setMetaData,
    setMetaDataFromPayload,
    isManualEntry,
    setIsManualEntry,
    movePreviousField,
    getMetaData,
    isSubmitting,
    setIsSubmitting,
    updateInfos,
    setCurrentCircularDate,
    setCurrentCircularStartDate,
    updateLimitedTimeDates,
    metaData,
    updateMetaData,
    setLiveCounterData
  } = useMainContext();

  //Loading States
  const [isLoading, setIsLoading] = useState(true);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  //For the skeleton partial
  const [imageHeight, setImageHeight] = useState(false);
  const [imageWidth, setImageWidth] = useState(false);

  // Page Data
  const [pageWidth, setPageWidth] = useState(1);
  const [pageHeight, setPageHeight] = useState(1);
  const [isLinerDisabled, setIsLinerDisabled] = useState(false);

  //Adblock Flags
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isSkipped, setIsSkipped] = useState(false);
  const [isDoNotEnter, setIsDoNotEnter] = useState(false);
  const [split, setSplit] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [isSubmitChildDisabled, setIsSubmitChildDisabled] = useState(true)
  const [isOfferAndIncentiveEmpty, setIsOfferAndIncentiveEmpty] = useState(true)
  const [isImported, setIsImported] = useState(false)

  //Base List Data
  const [pageData, setPageData] = useState([]);
  const [adblockData, setAdblockData] = useState([]);
  const [offerTypeData, setOfferTypeData] = useState([]);
  const [circularName, setCircularName] = useState("");
  const [circularType, setCircularType] = useState("");
  const [circularVersion, setCircularVersion] = useState("");
  const [groupsData, setGroupsData] = useState(null);
  const [sectionsData, setSectionsData] = useState(null);
  const [productData, setProductData] = useState([])
  const [sizeData, setSizeData] = useState([]);

  //Currently Viewed Information
  const [currentAdblockData, setCurrentAdblockData] = useState({});
  const [pageType, setpageType] = useState("");
  const [isDeepLinking, setIsDeepLinking] = useState(false);
  const [pageImg, setPageImg] = useState("");
  const [adblockFilename, setAdblockFilename] = useState("");

  //Offer list from adblock
  const [offerData, setOfferData] = useState([]);
  const currentOfferIndex = useInput(0);
  const [allOffersValid, setAllOffersValid] = useState(false);
  const [offerSuggestionData, setOfferSuggestionData] = useState(
    OFFER_INIT_SUGGESTIONS
  );

  //Incentives list from adblock
  const [incentiveData, setIncentiveData] = useState([]);
  const [incentiveSuggestionData, setIncentiveSuggestionData] = useState(
    INCENTIVE_INIT_SUGGESTION
  );

  //Adblock data
  const events = useInput([]);
  const [adblockCoords, setAdblockCoords] = useState({
    coords_x1: 0,
    coords_x2: 0,
    coords_y1: 0,
    coords_y2: 0,
  });
  const [sectionCoords, setSectionCoords] = useState({
    coords_x1: 0,
    coords_x2: 0,
    coords_y1: 0,
    coords_y2: 0,
  });
  const [startTime, setStartTime] = useState('');
  const [similarAdBlock, setSimilarAdBlock] = useState(null);

  //Form Flow
  const [activeOfferInfoText, setActiveOfferInfoText] = useState("");

  //Suggestions
  const [currentSuggestions, setCurrentSuggestions] = useState([]);

  //Modals and user information
  const endQueueModal = useModal();
  const deleteOfferModal = useModal();
  const imageMatchingModal = useModal();
  const { userInfo } = useAuthContext();
  const [deletingOffer, setDeletingOffer] = useState({});
  const [currentItemType, setCurrentItemType] = useState("offer");
  const [useDefaultSaveOfferText, setUseDefaultSaveOfferText] = useState(true);
  const [firstDefaultSaveOfferText, setFirstDefaultSaveOfferText] = useState('');
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  // Flag of Current Page's digital_coupon_f
  const [digitalCouponApplied, setDigitalCouponApplied] = useState(false);
  const pageDataRef = useRef(pageData);
  const digitalCouponAppliedRef = useRef(digitalCouponApplied);

  /*
  ///////////////////////////////////////
  ////// Start View Methods ////////////
  /////////////////////////////////////
  */

  const init = async () => {
    setStartTime(new Date().toISOString().replace(',',''));
    setCurrentPage("LR - Offer");
    setPreviousTime(new Date());
    setIsLoading(true);
    setSessionId(String(new Date()));
    setOfferData([]);
    setProductData([]);
    setIncentiveData([]);
    setImageHeight(blockRef.current.offsetHeight);
    setImageWidth(blockRef.current.offsetWidth);
    setMetaData(INIT_OFFER_METADATA);

    const data = await getOfferTypesApi();
    setOfferTypeData(
      data.payload.map((item) => ({
        ...item,
        id: item.name,
        title: item.name,
      }))
    );
    setOfferSuggestionData({
      ...offerSuggestionData,
      offer_type: data.payload
        .sort((a, b) => a.ext_id - b.ext_id)
        .slice(0, 9)
        .map((item) => {
          return {
            title: item.name,
            value: item.name,
          };
        }),
    });

    const res = await getProductSizesApi();
    setSizeData(res.payload.map((item) => ({ id: item.ext_id, title: item.size_c })));
    
    setIsLoading(false);
  };

  const initInfo = () => {
    setActiveField("");
    setMetaData(INIT_OFFER_METADATA);
  };

  const handleSaveOffers = (offers) => {
    offers.forEach((offer) => {
      if (offer.offer_type.toLowerCase() === 'save') {
        if (!offer.offer_text.includes("save ")) {
          offer.offer_text = "save " + offer.offer_text;
          setOfferData(offers);
        }
      }
    });
  }

  /*
  ///////////////////////////////////////
  ////// Watchers /////////////////////
  /////////////////////////////////////
  */

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      params?.circular !== "0" &&
      params?.page !== "0" &&
      params?.adblock !== "0"
    ) {
      setCurrentCircularId(params?.circular);
      setCurrentPageId(params?.page);
      setCurrentAdBlockId(params?.adblock);
      setIsDeepLinking(true);
    } else if (
      params?.circular !== "0" &&
      params?.page === "0" &&
      params?.adblock === "0"
    ) {
      setCurrentCircularId(params?.circular);
      setCurrentPageId("");
      setCurrentAdBlockId("");
    } else if (
      params?.circular !== "0" &&
      params?.page !== "0" &&
      params?.adblock === "0"
    ) {
      setCurrentCircularId(params?.circular);
      setCurrentPageId(params?.page);
      setCurrentAdBlockId("");
    }

    return () => {};
  }, [params?.circular, params?.page, params?.adblock]);

  useEffect(() => {
    if (currentAdBlockId) {
      setOfferData([]);
      setProductData([]);
      setIncentiveData([]);
      setCurrentInfo({});
      currentOfferIndex.setValue(0);
      fetchAdBlock(currentAdBlockId, true);
    }
    else {
      //unset all data
      setAdblockData([]);
      setCurrentAdblockData([]);
      setAdblockCoords({
        coords_x1: 0,
        coords_x2: 0,
        coords_y1: 0,
        coords_y2: 0,
      })
    }

    return () => {};
  }, [currentAdBlockId]);

  useEffect(() => {
    let firstOffer = [];
    if (offerData){
      firstOffer = offerData?.filter(item => 
        item?.offer_type.toLowerCase() === 'price ea' || item?.offer_type.toLowerCase() === 'bogo'  ||
        item?.offer_type.toLowerCase() === '& up' || item?.offer_type.toLowerCase() === 'x for' ||
        item?.offer_type.toLowerCase() === 'w/purchase/$' || item?.offer_type.toLowerCase() === 'w/purchase/qty' ||
        item?.offer_type.toLowerCase() === 'w/purchase/qty/$')
    }
    setUseDefaultSaveOfferText(false ? firstOffer.length > 0 : true);
    if (firstOffer.length > 0) {
      setUseDefaultSaveOfferText(false);
      let maxVal = 0;
      if (offerData && !currentInfo?.offer_text) {
        let findPriceEa = offerData.find(item => item.offer_type.toLowerCase() === "price ea");
        let findOffers = '';
        if (findPriceEa) {
          findPriceEa = findPriceEa.offer_text;
          let val = findPriceEa.split("-")
          // eslint-disable-next-line
          let numbers = val.map(item => item.replace(/[^\d\.]*/g, ''));
          maxVal = Math.max(...numbers)
        } else {
          findOffers = offerData.filter(item => item.offer_type.toLowerCase() !== "save")[0];
          if (findOffers) {
            if (findOffers.offer_type.toLowerCase() === 'bogo') {

            } else if (findOffers.offer_type.toLowerCase() === '& up') {
              maxVal = findOffers.offer_text.substring(1).split(' ')[0];
            } else if (findOffers.offer_type.toLowerCase() === 'x for') {
              maxVal = findOffers.offer_text.split('$')[1];
            } else if (findOffers.offer_type.toLowerCase() === 'w/purchase/$') {
              const reg = /w\/purchase\/\$(\d+(?:\.\d+)?)(?:-(\d+(?:\.\d+)?))?/;
              const match = findOffers.offer_text.match(reg);
              if (match) {
                const first = match[1];
                const second = match[2];
                if (first) {
                  if (second) {
                    maxVal = Math.max(first, second);
                  } else {
                    maxVal = first;
                  }
                }
              }
            } else if (findOffers.offer_type.toLowerCase() === 'w/purchase/qty') {
              const reg = /w\/purchase\/(\d+(?:\.\d+)?)(?:-(\d+(?:\.\d+)?))?/;
              const match = findOffers.offer_text.match(reg);
              if (match) {
                const first = match[1];
                const second = match[2];
                if (first) {
                  if (second) {
                    maxVal = Math.max(first, second);
                  } else {
                    maxVal = first;
                  }
                }
              }
            } else if (findOffers.offer_type.toLowerCase() === 'w/purchase/qty/$') {
              maxVal = findOffers.offer_text.split('$')[1];
            }
          }
        }
      }
      if (maxVal) {
        setFirstDefaultSaveOfferText(maxVal);
      }
    } else {
      setUseDefaultSaveOfferText(true);
    }
    return () => {};
  }, [offerData])

  useEffect(() => {
    if (currentCircularId) {
      fetchCircular();
    }
    return () => {};
  }, [currentCircularId]);

  useEffect(() => {
    if (
      isManualEntry &&
      activeField !== "" &&
      activeField.indexOf("_f") === -1 &&
      activeField !== "offer_type"
    ) {
      //Setting the timeout prevents the keypress from populating an input
      setTimeout(() => {
        if (document.getElementById(activeField))
          document.getElementById(activeField).focus();
      });
    }
    return () => {};
  }, [isManualEntry]);

  useEffect(() => {
    setAllOffersValid(
      offerData.every(function (e) {
        // eslint-disable-next-line
        return e?.offer_type === "Price ea" ? (e.reg_price && setIsSubmitDisabled(!(parseFloat(e.reg_price?.toString().replace(/[^0-9\.]+/g, "")) >= parseFloat(e.offer_text.split('-')[0]?.toString().replace(/[^0-9\.]+/g, ""))))) : e?.isValid;
      }) &&
      incentiveData.every(function (e) {
        return e?.isValid;
      })
    );
    return () => { };
  }, [offerData, incentiveData]);

  useEffect(() => {
    // Checking if Brand Equity is selected
    const isBrandEquitySelected = metaData.length > 1 && metaData[1].value;
    const disableApprove = isBrandEquitySelected ? (offerData.length || incentiveData.length && incentiveData.some(i => i.incentive_attach_to === 'Adblock')) : !offerData.length && !incentiveData.length;

    setIsSubmitDisabled(isBrandEquitySelected ? disableApprove : disableApprove || isSubmitChildDisabled)
    setIsOfferAndIncentiveEmpty(disableApprove);
    return () => {};
  }, [offerData, incentiveData, metaData, isSubmitChildDisabled]);

  useEffect(() => {
    if (circularType === 'Training Circular'){
        setCurrentSuggestions([]);
    } else {
    if (activeField && currentItemType) {
      setCurrentSuggestions(
        currentItemType === "offer"
          ? offerSuggestionData[activeField]
          : incentiveSuggestionData[activeField] || []
      );
      if (
        currentInfo["offer_type"] === "w/purchase case" &&
        activeField === "offer_text"
      ) {
        setCurrentSuggestions([
          {
            value: "w/purchase whole case",
            title: "w/purchase whole case",
          },
          {
            value: "w/purchase half case",
            title: "w/purchase half case",
          },
        ]);
      } else if (
        currentInfo["offer_type"] === "w/purchase product" &&
        activeField === "offer_text"
      ) {
        let products = [];
        if (
          currentAdblockData?.products &&
          currentAdblockData?.products.length > 0
        ) {
          products =
            currentAdblockData?.products.map((p) => ({
              title: p.brand_nm,
              value: p.brand_nm,
            })) || [];
        } else if (
          currentAdblockData?.suggestions?.products &&
          currentAdblockData?.suggestions?.products.length > 0
        ) {
          products =
            currentAdblockData?.suggestions?.products.map((p) => ({
              title: p.brand_nm,
              value: p.brand_nm,
            })) || [];
        }
        setCurrentSuggestions(products);
      } else if (
        [
          "Clearance",
          "Extra Coupons",
          "Free Shipping",
          "Pharmacy Services",
          "Rollback",
          "Saturdate",
          "Sweepstakes",
          "Earn Free Item",
          "w/agreement",
        ].includes(currentInfo["incentive_type"]) &&
        activeField === "incentive_text"
      ) {
        setCurrentSuggestions(
          EXTRA_INCENTIVE_SUGGESTIONS[currentInfo["incentive_type"]]
        );
      }
      setActiveOfferInfoText(
        currentItemType === "offer"
          ? OFFER_SUGGESTION_TITLE[activeField]
          : INCENTIVE_SUGGESTION_TITLE[activeField]
      );
    }
  }

    return () => {};
  }, [activeField]);

  useEffect(() => {
    if (Object.keys(currentInfo).length > 0) {
      if (currentItemType === "offer") {
        let temp = [...offerData];
        temp[currentOfferIndex.value] = {
          ...currentInfo,
          isValid: validateOffer(currentInfo),
        };
      setOfferData(temp);
      handleSaveOffers(temp);
      } else if (currentItemType === "incentive") {
        // let temp = [...incentiveData];
        // setIncentiveData(
        //   temp.map((item) => {
        //     if (item.index === currentInfo.index)
        //       return {
        //         ...currentInfo,
        //         isValid: validateIncentive(currentInfo),
        //       };
        //     return { ...item };
        //   })
        // );

        let temp2 = [...incentiveData];
        temp2[currentInfo.index] = {
          ...currentInfo,
          isValid: validateIncentive(currentInfo),
        };
        console.log("incentive ===", currentInfo, temp2);
        setIncentiveData(temp2);
      }
    }
    return () => {};
  }, [currentInfo]);

  useEffect(()=> {
    if (productData){
      let linerExists = productData.find(item => item.liner_f === true);
      if (linerExists){
        setIsLinerDisabled(false);
      } else {
        setIsLinerDisabled(true);
      }
    }
  }, [productData])

  useEffect(() => {
    if (currentAdblockData.similar_adblock_uuid && !currentAdblockData.offers_approved && !currentAdblockData.is_dupe) {
      imageMatchingModal.openModal()
    }
  }, [currentAdblockData])

  useEffect(async () => {
    if (pageData) {
      pageDataRef.current = pageData;
      const pageIndex = pageData.findIndex((item) => item.uuid === currentPageId);
      if (pageIndex >= 0)
        setDigitalCouponApplied(Boolean(pageData[pageIndex]?.digital_coupon_f))
    }
  }, [pageData])

  useEffect(() => {
    digitalCouponAppliedRef.current = digitalCouponApplied;
  }, [digitalCouponApplied])

  /*
  ///////////////////////////////////////
  ////// Fetch Main Data ////////////////
  /////////////////////////////////////
  */

  const fetchCircular = async () => {
    // const cirIncentiveData = await getCircularIncentives(currentCircularId);
    // setIncentiveSuggestionData({
    //   ...incentiveSuggestionData,
    //   incentive_text: cirIncentiveData.map((item) => ({
    //     title: item,
    //     value: item,
    //   })),
    // });
    const result = await getCircularById(currentCircularId);
    if (result.success) {
      //setCircularData(result.payload);
      setCircularName(
        result.payload.circ_subtype +
          "_" +
          result.payload.retailer.name +
          "_" +
          format(
            new Date(
              result.payload.circ_week_dt.replace(/-/g, "/").replace(/T.+/, "")
            ),
            "MM/dd/yyyy"
          ) +
          `_${result.payload.ext_id}` +
          `_${result.payload.circ_version ? result.payload.circ_version : 0}`
      );
      setCircularType(result.payload.circ_type);
      if (result.payload.circ_type === 'Training Circular'){
        setCurrentSuggestions([]);
      }
    } else {
      //TODO: set error message about no circular matching
    }
    if(result.payload.circ_week_dt && result.payload.start_dt)
    {
      setCurrentCircularDate(format(new Date(result.payload.circ_week_dt.replace(/-/g, '/').replace(/T.+/, '')), "MM-dd-yyyy"));
      setCurrentCircularStartDate(format(new Date(result.payload.start_dt.replace(/-/g, '/').replace(/T.+/, '')), "MM-dd-yyyy"));
    }
  };

  const onPageSelect = async (params, data = [], initAfter = true) => {
    setCurrentPageId(params);
    setPageData(data);
    const currentPage = data.find((page) => page.uuid === params);
    if (currentPage) {
      const pageType = PAGE_TYPES.find((page) => page.page_c === currentPage.page_loc);
      setpageType(pageType.page_nm);

      let filename = currentPage.filename;
      let width = currentPage.img_width;
      let height = currentPage.img_height;
      if(currentPage.is_doubletruck) {
        filename = currentPage.doubletruck_filename;
        width = currentPage.img_width * 2;
        height = currentPage.img_height * 2;
      }
      setDigitalCouponApplied(Boolean(currentPage?.digital_coupon_f))
      let img
      const cachedImage = await getCachedImage(filename);
      if (!cachedImage) {
        img = await getImage(filename);
        if (img.success)
          await setCachedImage(filename, img.payload)
      }
      setPageWidth(width);
      setPageHeight(height);
      setPageImg(cachedImage ? cachedImage : img.payload);
    }
    if (!isDeepLinking) {
      setCurrentAdBlockId("");
    }
  };

  const fetchAdBlock = async (  
    adblockId,
    showLoading = true
  ) => {
    setIsFetching(true && showLoading);
    setIsImported(false);
    setIsApproved(false);
    setIsRejected(false);
    setIsDoNotEnter(false);
    setIsSkipped(false);
    setIncentiveData([]);
    setGroupsData(null);
    setSectionsData(null);
    setCurrentAdblockData([]);
    setProductData([]);
    setMetaData(INIT_OFFER_METADATA);
    const res = await getCurrentUserAPI();
    if (res.success) {
      setLiveCounterData(res.results);
    }
    const result = await getAdBlockById(currentAdBlockId);
    if (result.success) {
      let payload = result.payload;
      if (payload) {
        let updateOfferSggs4DigitalCoupon = false;
        setIsManualEntry(true);
        setCurrentAdblockData(payload);
        setIsDoNotEnter(payload?.do_not_enter_f);
        setIsSkipped(payload?.skipped);
        setIsRejected(payload?.rejected);
        setSplit(payload?.split > 0 ? true : false);
        setGroupsData(payload?.group);
        setSectionsData(payload?.section);
        setAdblockCoords({
          coords_x1: payload.coords_x1,
          coords_x2: payload.coords_x2,
          coords_y1: payload.coords_y1,
          coords_y2: payload.coords_y2,
        });
        if (payload.section) {
          setSectionCoords({
            coords_x1: payload.section.coords_x1,
            coords_x2: payload.section.coords_x2,
            coords_y1: payload.section.coords_y1,
            coords_y2: payload.section.coords_y2,
          });
        }
        else {
          setSectionCoords({ coords_x1: 0, coords_x2: 0, coords_y1: 0, coords_y2: 0 })
        }
        
        let eventsList = [];
        if (payload.products_approved) {
          eventsList = payload?.event_nm ? payload?.event_nm.split(",") : [];
        } else {
          if (payload.group !== null && payload.group.event_type && !payload.offers_approved) {
            eventsList = payload.group.event_text.split(',');
          }
        }
        events.setValue(eventsList);

        let offersData = [];
        let incentivesData = [];
        setIsApproved(payload.offers_approved);
        setIsManualEntry(payload.offers_approved);

        //This means the adblock is approved

        // if (payload.offers_approved) {
        if(payload.offers.length > 0) {
          offersData = payload.offers.map((item, index) => ({
            ...item,
            isValid: validateOffer(item),
            index,
          }));
        } else if (
          payload?.suggestions &&
          payload?.suggestions?.offers.length > 0 &&
          !payload?.offers_approved
        ) {
          offersData = payload.suggestions.offers.map((item, index) => ({
            ...item,
            offer_type: item.offer_type.toLowerCase() === 'price ea' ? 'Price ea' :  item.offer_type,
            isValid: validateOffer(item),
            index,
          }));
          updateOfferSggs4DigitalCoupon = true;          
        } else {
          initInfo();
        }
        if(payload.group !== null && payload.group.offer_type && !payload.offers_approved) {
          let group_offers = payload.group.offer_text.split(",")
          group_offers.forEach(
            offer => {
              const newIndex = offersData.length > 0 ? offersData[offersData.length - 1].index + 1 : 0;
              const newOffer = {
                ...OFFER_INIT_INFO,
                index: newIndex,
                isValid: true,
              };
              let text = offer;
              let offer_text = extractTextBetweenFirstBalancedParenthesis(text);
              let offer_type = 'Group'; // Temporary for existing Offer Text
              if (offer_text) {
                let index = offer_text.indexOf(" - ")
                offer_type = offer_text.slice(0, index).trim();
                offer_text = offer_text.slice(index + 2).trim();
                let reg_price = text.match(/\(RPS:(.+?)\)/);
                reg_price = Array.isArray(reg_price) ? reg_price[1] : ''
                let reg_price2_d = text.match(/\(RPS2:(.+?)\)/);
                reg_price2_d = Array.isArray(reg_price2_d) ? reg_price2_d[1] : ''
                let limit = text.match(/\(Limit:(.+?)\)/);
                limit = Array.isArray(limit) ? limit[1] : ''
                let min_qty_dollar_f = text.match(/\(MinQty:(.+?)\)/) ? true : false;
                let min_qty = min_qty_dollar_f ? text.match(/\(MinQty:(.+?)\)/) : null;
                min_qty = Array.isArray(min_qty) ? min_qty[1] : ''
                let must_buy_f = text.match(/\(MustBuy\)/) ? true : false;
                let mix_and_match_f = text.match(/\(MixAndMatch\)/) ? true : false;
                let include_in_net_cost_f = text.match(/\(NetCost\)/) ? true : false;
                let liner_f = text.match(/\(Liner\)/) ? true : false;
                newOffer.reg_price = reg_price;
                newOffer.reg_price2_d = reg_price2_d;
                newOffer.limit = limit;
                newOffer.min_qty_dollar_f = min_qty_dollar_f
                newOffer.min_qty = min_qty
                newOffer.must_buy_f = must_buy_f;
                newOffer.mix_and_match_f = mix_and_match_f;
                newOffer.include_in_net_cost_f = include_in_net_cost_f;
                newOffer.liner_f = liner_f;
              } else {
                offer_text = text;
              }
              newOffer.offer_text = offer_text;
              newOffer.offer_type = offer_type;
              offersData = [...offersData, newOffer];
            }
          )
        }

        if(payload.group !== null && payload.group.incentive_type && !payload.offers_approved) {
          let group_incentives = getIncentiveInfo(payload.group.incentive_text);
          group_incentives.forEach(
            incentives => {
              const newIndex = incentivesData.length > 0 ?
                incentivesData[incentivesData.length - 1].index + 1 : 0;
              const newIncentive = {
                incentive_type: incentives.incentive_type,
                incentive_text: incentives.incentive_text,
                index: newIndex,
                isValid: true,
                group: true,
                incentive_attach_to: 'Adblock',
                incentive_limit: incentives.limit,
                incentive_include_net_cost: incentives.net_cost,
                incentive_by_mail: incentives.by_mail,
                incentive_online_incentive: incentives.online_incentive,
                incentive_liner_f: incentives.incentive_liner_f
              };
              incentivesData = [...incentivesData, newIncentive];
            })
        }
        const existingSuggestion = await getSimilarAdBlockOffers(currentAdBlockId);
        let existingOfferSuggestion = {};
        if (existingSuggestion.success) {
          existingOfferSuggestion = existingSuggestion.payload;
          if (
            !payload?.offers_approved &&
            existingOfferSuggestion &&
            existingOfferSuggestion?.offers) {
            if (existingOfferSuggestion?.offers.length > 0) {
              const newIndex = offersData.length > 0 ? offersData[offersData.length - 1].index + 1 : 0;
              const newOffer = existingOfferSuggestion.offers.map((item, index) => ({
                ...item,
                isValid: validateOffer(item),
                index: newIndex + index,
                existingOffer: true
              }));
              offersData = [...offersData, ...newOffer];
            }
          }
        }

        if (updateOfferSggs4DigitalCoupon && digitalCouponAppliedRef.current)
          updateOffersBasedOnDigitalCoupon(offersData, setOfferData);
        else
          await setOfferData(offersData);
        
        handleSaveOffers(offersData);  
        setProductData(payload.products);
        let liner_disabled = false
        if (payload.products){
          liner_disabled = !payload.products?.find(item => item.liner_f === true);
        }
        if ((payload.split && payload.products.length !== offersData.length)){
          setIsSubmitDisabled(true);
        }

        //First split the incentives
        let tempIncentives = [];
        if (payload?.incentive_text) {
          getIncentiveInfo(payload?.incentive_text).forEach(
            incentives => {
              const newIncentive = {
                incentive_type: incentives.incentive_type,
                incentive_text: incentives.incentive_text,
                index: incentivesData.length > 0 ? incentivesData[incentivesData.length - 1].index + 1 : 0,
                isValid: true,
                incentive_attach_to: 'Adblock',
                incentive_limit: incentives.limit,
                incentive_include_net_cost: incentives.net_cost,
                incentive_by_mail: incentives.by_mail,
                incentive_online_incentive: incentives.online_incentive,
                incentive_liner_f: incentives.incentive_liner_f
              };
              incentivesData = [...incentivesData, newIncentive];
            })
        }

        if (!payload?.offers_approved && payload.suggestions?.incentives) {
          let incentive_suggestions = payload.suggestions.incentives;
          incentive_suggestions.forEach(
            incentives => {
              const newIncentive = {
                incentive_type: incentives.incentive_type,
                incentive_text: incentives.incentive_text,
                index: incentivesData.length > 0 ? incentivesData[incentivesData.length - 1].index + 1 : 0,
                isValid: true,
                incentive_attach_to: 'Adblock',
                incentive_limit: incentives.limit,
                incentive_include_net_cost: incentives.net_cost,
                incentive_by_mail: incentives.by_mail,
                incentive_online_incentive: incentives.online_incentive,
                incentive_liner_f: incentives.incentive_liner_f
              };
              incentivesData = [...incentivesData, newIncentive];
            })
        }

        // Remove this to use Page/Circular Data to fetch incentive 
        if (
          !payload?.offers_approved &&
          existingOfferSuggestion &&
          existingOfferSuggestion?.incentives) {
          if (existingOfferSuggestion?.incentives.length > 0) {
            const existingIncent = existingOfferSuggestion?.incentives.toString()
            if (existingIncent !== "") {
              getIncentiveInfo(existingIncent).forEach(
                incentives => {
                  const newIncentive = {
                    incentive_type: incentives.incentive_type,
                    incentive_text: incentives.incentive_text,
                    index: incentivesData.length > 0 ? incentivesData[incentivesData.length - 1].index + 1 : 0,
                    isValid: true,
                    incentive_attach_to: 'Adblock',
                    incentive_limit: incentives.limit,
                    incentive_include_net_cost: incentives.net_cost,
                    incentive_by_mail: incentives.by_mail,
                    incentive_online_incentive: incentives.online_incentive,
                    incentive_liner_f: incentives.incentive_liner_f,
                    existingIncentive: true,
                  };
                  incentivesData = [...incentivesData, newIncentive];
                })
            }
          }
        }
        const pageIncentive = await getPageIncentive(currentPageId);
        console.log(pageIncentive)
        if (pageIncentive?.incentive) {
          getIncentiveInfo(pageIncentive?.incentive).forEach(
            incentives => {
              const newIncentive = {
                incentive_type: incentives.incentive_type,
                incentive_text: incentives.incentive_text,
                index: incentivesData.length > 0 ? incentivesData[incentivesData.length - 1].index + 1 : 0,
                isValid: true,
                incentive_attach_to: 'Page',
                incentive_limit: incentives.limit,
                incentive_include_net_cost: incentives.net_cost,
                incentive_by_mail: incentives.by_mail,
                incentive_online_incentive: incentives.online_incentive,
                incentive_liner_f: incentives.incentive_liner_f
              };
              incentivesData = [...incentivesData, newIncentive];
            })
        }
        const circularIncentive = await getCircularIncentive(currentCircularId);
        console.log("circ", circularIncentive?.incentive, currentCircularId)
        if (circularIncentive?.incentive) {
          getIncentiveInfo(circularIncentive?.incentive).forEach(
            incentives => {
              const newIncentive = {
                incentive_type: incentives.incentive_type,
                incentive_text: incentives.incentive_text,
                index: incentivesData.length > 0 ? incentivesData[incentivesData.length - 1].index + 1 : 0,
                isValid: true,
                incentive_attach_to: 'Circular',
                incentive_limit: incentives.limit,
                incentive_include_net_cost: incentives.net_cost,
                incentive_by_mail: incentives.by_mail,
                incentive_online_incentive: incentives.online_incentive,
                incentive_liner_f: incentives.incentive_liner_f
              };
              incentivesData = [...incentivesData, newIncentive];
            })
        }

        if (tempIncentives.length > 0 && incentiveData.length > 0){
          setIncentiveData(...incentivesData, tempIncentives);
        } else if (tempIncentives.length > 0 && !incentivesData.length > 0) {
          setIncentiveData(tempIncentives);
        } else {
          setIncentiveData(incentivesData);
        }

        if (offersData.length > 0) {
          setCurrentInfo(offersData[0]);
          currentOfferIndex.setValue(0);
          setCurrentItemType("offer");
          setFieldNames(liner_disabled ? OFFER_INFO_FIELDS.filter(offer => offer !== 'liner_f') : OFFER_INFO_FIELDS);
          setOfferSuggestionData({
            ...offerSuggestionData,
            offer_text: offersData.slice(0, 9).map((item, index) => {
              return {
                title: item.offer_text,
                value: item.offer_text,
              };
            }),
          });
        }

        // Enable Coupon Meta Check if Coupon Page
        const metaDataFromPayload = {
          ...result.payload.adblock,
          coupon_f: true
        }
        setMetaDataFromPayload(!payload.offers_approved && pageDataRef.current.find((item) => item.uuid === currentPageId)?.page_loc === "4" ? metaDataFromPayload : payload);

        if(payload.group !== null && payload.group.limited_time && !payload.offers_approved) {
          const start = payload.group?.start;
          const end = payload.group?.end;
          updateLimitedTimeDates({
            limitedDateFrom: start,
            limitedDateTo: end,
          }, true)
        }
        setAdblockFilename(payload.filename);

        postPageEvents("ready", "");
        navigate(`/offer/${currentCircularId}/${currentPageId}/${adblockId}`);
      }
    }
    setIsFetching(false && showLoading);
  };

  const handleAdblockSelect = (params, data = []) => {
    setIsDeepLinking(false);
    if (!params && data.length === 0) {
      setCurrentAdBlockId("");
      setAdblockData([]);
      setCurrentSuggestions([]);
      setOfferData([]);
      setProductData([]);
      initInfo();
      setIsFetching(false);
      navigate(`/offer/${currentCircularId}/${currentPageId}/0`);
    } else {
      setCurrentAdBlockId(params);
      // setAdblockData(data);
      // fetchAdBlock(params, data);
    }
    setStartTime(new Date().toISOString().replace(',',''));
  };

  const onAdblockDataLoad = (params = []) => {
    setAdblockData(params);
  };

  const refetchAdblocks = async () => {
    const result = await getPageCrops(currentPageId);
    const newData = result?.payload?.adblocks;
    setAdblockData(newData);
  };
  const navigateNext = () => {
    //Turn manual entry off when going to next set
    setIsManualEntry(false);
    setStartTime(new Date().toISOString().replace(',',''));

    const pageIndex = pageData.findIndex((item) => item.uuid === currentPageId);
    const adblockIndex = adblockData.findIndex(
      (item) => item.uuid === currentAdBlockId
    );
    if (adblockIndex < adblockData.length - 1) {
      setCurrentAdBlockId(adblockData[adblockIndex + 1].uuid);
    } else if (
      adblockIndex === adblockData.length - 1 &&
      pageIndex < pageData.length - 1
    ) {
      setCurrentPageId(pageData[pageIndex + 1].uuid);
      setCurrentAdBlockId("");
    } else if (
      adblockIndex === adblockData.length - 1 &&
      pageIndex === pageData.length - 1
    ) {
      endQueueModal.openModal();
    }
  };
  
  const navigateNextUnapproved = () => {
    setIsManualEntry(false);
    setStartTime(new Date().toISOString().replace(',',''));
    const pageIndex = pageData.findIndex((item) => item.uuid === currentPageId);
    const adblockIndex = adblockData.findIndex((item) => item.uuid === currentAdBlockId);

    var nextBlock = null;
    if (adblockIndex < adblockData.length - 1) {
      nextBlock = adblockIndex + 1;

      while(nextBlock < adblockData.length - 1 && adblockData[nextBlock].offers_approved){
        nextBlock = nextBlock + 1;
      }

      if(!adblockData[nextBlock].offers_approved){
        setCurrentAdBlockId(adblockData[nextBlock].uuid);
      }
      else{
        nextBlock = null;
      }
    }

    if(!nextBlock){ 
      if (
        adblockIndex === adblockData.length - 1 &&
        pageIndex < pageData.length - 1
      ) {
        setCurrentPageId(pageData[pageIndex + 1].uuid);
        setCurrentAdBlockId("");
      } else if (
        adblockIndex === adblockData.length - 1 &&
        pageIndex === pageData.length - 1
      ) {
        endQueueModal.openModal();
      }
    }
  };

  /*
  ///////////////////////////////////////
  ////// Set Adblock Flags /////////////
  /////////////////////////////////////
  */

  const handleReject = async (reject_message, remove_children) => {
    setIsSubmitting(true);
    let result = await rejectAdblock(currentAdBlockId, "offer", reject_message, remove_children);
    if (result.success) {
      // Update adblocks
      result = await getPageCrops(currentPageId);
      setAdblockData(result?.payload?.adblocks || []);

      navigateNextUnapproved();
    } else {
      //TODO: Show error message
    }
    setIsSubmitting(false);
  };

  const handleApprove = async () => {
    setIsSubmitting(true);

    const int_keys = [
      "reg_price",
      "reg_price2_d",
      "limit",
      "coupon_amount",
      "rebate_amount",
      "min_qty",
    ];
    
    // Check if offerData has isValid false or empty values
    let inValidOffer = offerData.some((offer) => !offer.isValid);
    if (inValidOffer) {
      setIsSubmitting(false);
      setIsSubmitDisabled(true);
      toast.error("Invalid Offer Entered", {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    let offers = offerData.map((offer) => omit(offer, [
      // "index", 
      "isValid"]));
    if (productData.length < 1) {
      setIsSubmitDisabled(true);
      setIsSubmitting(false);
      return
    }
    if ((offers.length < 1 && incentiveData.length < 1 && !metaData[1].value)) {
      setIsSubmitDisabled(true);
      setIsSubmitting(false);
      return
    }
    let index = 0;
    offers.forEach((offer) => {
      Object.entries(offer).forEach(([key, value]) => {
        if (value === "" && int_keys.indexOf(key) !== -1) {
          offer[key] = null;
        }
      });

      offer['order_num'] = index;
      index++;

    });

    offers = offers.map(offer => omit(offer, 'index'));

    for (const value of offers) {
      try {
        await offerValidationSchema.validate(value, { abortEarly: false });
      } catch (err) {
        console.error('Validation failed');
        if (err.name === 'ValidationError') {
          err.inner.forEach((error) => {
            setIsSubmitting(false);
            toast.error(`${error.message}`, {
              position: "bottom-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
              theme: "dark",
            });
          });
        }
        return;
      }
    }

    const adblock = {
      ...getMetaData(),
      split: split ? 1 : 0,
      event_nm: events.value.join(),
      incentive_text: incentiveData
        .filter(
          (a) => validateIncentive(a) && a?.incentive_attach_to === "Adblock"
        )
        .map((a) => `${a?.incentive_liner_f ? `(L)`
          :''}${a?.incentive_type ? `(${a?.incentive_type}) `
          : ''}${a.incentive_text ? a.incentive_text
            : ''} (${a?.incentive_limit ? `${a?.incentive_limit}`
              : ""}) (${a?.incentive_include_net_cost ? `Net Cost`
                : ''}) (${a?.incentive_by_mail ? `1`
                  : `0`}) (${a?.incentive_online_incentive ? `1`
                    : `0`})`)
        .join(","),
    }

    const endTime = new Date().toISOString().replace(',','')

    if (!isImported) {
      adblock.ltr_offer_started_at = startTime
      adblock.ltr_offer_finished_at = endTime
    } else {
      adblock.offer_import_started_at = startTime
      adblock.offer_import_finished_at = endTime
    }

    let data = {
      adblock: adblock,
      offers: offers,
    };

    await saveCircularIncentives(
      currentCircularId,
      incentiveData.filter(
        (a) => validateIncentive(a) && a?.incentive_attach_to === "Circular"
      )
    );
    await savePageIncentives(
      currentPageId,
      incentiveData.filter(
        (a) => validateIncentive(a) && a?.incentive_attach_to === "Page"
      )
    );

    console.log('approve data', data);
    let result = await approveOffer(currentAdBlockId, data);
    if (result.success) {
      //Update adblocks
      const res = await getCurrentUserAPI();
      if (res.success) {
        setLiveCounterData(res.results);
      }
      result = await getPageCrops(currentPageId);
      setAdblockData(result?.payload?.adblocks || []);
      setIsApproved(true);
      navigateNextUnapproved();
    } else {
      //TODO: Show error message
    }
    setIsSubmitting(false);
  };

  const handleSkipAdblock = async () => {
    setIsSubmitting(true);
    let result = await skipAdblock(currentAdBlockId, {}, "offers");
    if (result.success) {
      // Update adblocks
      result = await getPageCrops(currentPageId);
      setAdblockData(result?.payload?.adblocks || []);
      navigateNextUnapproved();
    } else {
      //TODO: Show error message
    }
    setIsSubmitting(false);
  };

  const handleOpenModal = () => {
    setConfirmationMessage("Are you sure you want to mark this block as DNE?");
    setConfirmationModalOpen(true);
  };

  const handleCancelConfirmation = () => {
    setConfirmationModalOpen(false);
  };

  const handleContinue = async () => {
    setConfirmationModalOpen(false);
    await handleDoNotEnterAdblock();
  };

  const handleDoNotEnterAdblock = async () => {
    setIsSubmitting(true);
    let result = await doNotEnterAdblock(currentAdBlockId, {
      ltr_offer_started_at: startTime,
      ltr_offer_finished_at: new Date().toISOString().replace(',',''),
    }, "offers");
    if (result.success) {
      // Update adblocks
      result = await getPageCrops(currentPageId);
      setAdblockData(result?.payload?.adblocks || []);
      const res = await getCurrentUserAPI();
      if (res.success) {
        setLiveCounterData(res.results);
      }
      navigateNextUnapproved();
    } else {
      //TODO: Show error message
    }
    setIsSubmitting(false);
  };

  const handleAddItem = (type) => {
    switch (type) {
      case "offer":
        handleAddOffer();
        break;
      case "incentive":
        handleAddIncentive();
        break;

      default:
        break;
    }
    setCurrentItemType(type);
  };

  const handleSplit = (value) => {
    console.log('split', value);
    setSplit(value);
  }


  /*
  ///////////////////////////////////////
  ////// Offer Controls /////////////
  /////////////////////////////////////
  */

  const handleAddOffer = () => {
    const newIndex =
      offerData.length > 0 ? offerData[offerData.length - 1].index + 1 : 0;
    const newOffer = {
      ...OFFER_INIT_INFO,
      index: newIndex,
      isValid: false,
    };
    //We need to always have net cost and total purchase checked
    newOffer.include_in_net_cost_f = true;
    setOfferData([...offerData, newOffer]);
    currentOfferIndex.setValue(newIndex);
      setCurrentInfo(newOffer);
      setFieldNames(isLinerDisabled ? OFFER_INFO_FIELDS.filter(offer => offer !== 'liner_f') : OFFER_INFO_FIELDS);
    setCurrentItemType("offer");
    console.log("offer index", newIndex);
  };

  const handleRemoveOffer = (event, index) => {
    const curr = offerData.find((offer) => offer.index === index)
    if (curr?.uuid) {
      deleteOfferModal.openModal();
    }
    else {
      const idx = offerData.findIndex((offer) => offer.index === index);
      console.log("delete offer idx", idx);
      const newOffersData = offerData
        .filter((item) => item?.index !== index)
        .map((item, index) => ({
          ...item,
          isValid: validateOffer(item),
          index,
        }));

      setOfferData(newOffersData);

      if (currentItemType === "offer") {
        if (newOffersData.length === 0) {
          setCurrentInfo({});
          currentOfferIndex.setValue(0);
          if (incentiveData.length > 0) {
            setCurrentInfo(incentiveData[0]);
            setCurrentItemType("incentive");
          }
        } else {
          const newIdx = newOffersData.length > idx ? idx : idx - 1;
          currentOfferIndex.setValue(newOffersData[newIdx].index);
          console.log("current info 111 ===", newOffersData[newIdx]);
          setCurrentInfo(newOffersData[newIdx]);
        }
      }
    } 
    setDeletingOffer(offerData.find((offer) => offer.index === index));
  };

  const handleCloneOffer = (event, index) => {
    event.stopPropagation();
    if (offerData.length > 0) {
      const newIndex = offerData[offerData.length - 1].index + 1;
      setOfferData([
        ...offerData,
        { ...currentInfo, index: newIndex, uuid: undefined },
      ]);
      currentOfferIndex.setValue(newIndex);
    }
  };

  const handleOfferSelect = (index) => {
    currentOfferIndex.setValue(index);
    setCurrentItemType("offer");
    setFieldNames(isLinerDisabled ? OFFER_INFO_FIELDS.filter(offer => offer !== 'liner_f') : OFFER_INFO_FIELDS);
    setActiveField(OFFER_INFO_FIELDS[0]);
    setCurrentInfo(offerData[index]);
  };
  ///////////////////////////////////////
  ////// Incentive Controls /////////////
  /////////////////////////////////////

  const handleAddIncentive = () => {
    const newIndex =
      incentiveData.length > 0
        ? incentiveData[incentiveData.length - 1].index + 1
        : 0;
    const newIncentive = {
      ...INCENTIVE_INIT_INFO,
      index: newIndex,
      isValid: false,
      isAddedIncentive:true
    };
    setIncentiveData([...incentiveData, newIncentive]);
    setCurrentItemType("incentive");
    setFieldNames(INCENTIVE_INFO_FIELDS);
    setCurrentInfo(newIncentive);
  };

  const handleRemoveIncentive = async (event, index) => {
    const idx = incentiveData.findIndex(
      (incentive) => incentive.index === index
    );
    const newIncentivesData = incentiveData
      .filter((item) => item?.index !== index)
      .map((item, index) => ({
        ...item,
        isValid: validateIncentive(item),
        index,
      }));


    let newCurrentInfo = {};
    if (currentItemType === "incentive") {
      if (newIncentivesData.length === 0) {
        newCurrentInfo = { index: 0 };
        setCurrentItemType("");
        if (offerData.length > 0) {
          console.log("offerData", offerData);
          setCurrentItemType("offer");
          currentOfferIndex.setValue(offerData[0].index);
          setCurrentInfo(offerData[0]);
        }
      } else {
        const newIdx =
          newIncentivesData.length === 1
            ? 0
            : newIncentivesData.length > idx
            ? idx
            : idx - 1;
        newCurrentInfo = newIncentivesData[newIdx];
        setCurrentInfo(newCurrentInfo);
      }
    }
    

    const curr = incentiveData.find((incentive) => incentive.index === index)
    if (isApproved && curr.isAddedIncentive == null && curr.isClonedIncentive == null) {
      const result = await unApproveOffer(currentAdBlockId);
      if (result.success) {
        setIsApproved(false);
      }
    }

    setIncentiveData(newIncentivesData);
  };

  const handleCloneIncentive = (event, index) => {
    event.stopPropagation();

    if (incentiveData.length > 0) {
      const newIndex = incentiveData[incentiveData.length - 1].index + 1;
      setIncentiveData([
        ...incentiveData,
        { ...incentiveData[index], isClonedIncentive:true, index: newIndex, uuid: undefined },
      ]);
    }
  };

  const handleIncentiveSelect = (index) => {
    console.log("incentive select", index, { ...incentiveData[index] });
    setCurrentItemType("incentive");
    setFieldNames(INCENTIVE_INFO_FIELDS);
    setActiveField(INCENTIVE_INFO_FIELDS[0]);
    setCurrentInfo(incentiveData[index]);
  };

  useEffect(() => {
    if (offerData.length === 0 && incentiveData.length === 0) {
      setCurrentItemType("");
      setActiveField("");
    }
    // else if (offerData.length === 0 && incentiveData.length > 0) {
    //   setCurrentItemType("incentive");
    // } else if (offerData.length > 0 && incentiveData.length === 0) {
    //   setCurrentItemType("offer");
    // }
    if (split && offerData.length !== productData.length){
      setIsSubmitDisabled(true);
    }
        return () => {};
  }, [split, offerData, incentiveData]);


  /*
  ///////////////////////////////////////
  ////// Adblock Refresh ///////////////
  /////////////////////////////////////
  */

  const refreshAdblockSuggestions = async () => {
    postPageEvents("click", "Refresh Quick Selection");
    setSuggestionsLoading(true);
    const result = await updateAdblockSuggestions(currentAdBlockId);
    if (result.success) {
      let offers = [];
      setIsApproved(result.payload.offers_approved);

      //This means the adblock is approved
      if (result.payload.offers.length > 0) {
        offers = result.payload.offers.map((item, index) => ({
          ...item,
          isValid: validateOffer(item),
          index,
        }));
      } else if (result.payload.suggestions.offers.length > 0) {
        offers = result.payload.suggestions.offers.map((item, index) => ({
          ...item,
          isValid: validateOffer(item),
          index,
        }));
      }
      setOfferData(offers);
      if (offers.length > 0) {
        setCurrentInfo(offers[0]);
        currentOfferIndex.setValue(0);
        setCurrentItemType("offer");
        setFieldNames(isLinerDisabled ? OFFER_INFO_FIELDS.filter(offer => offer !== 'liner_f') : OFFER_INFO_FIELDS);
      }
    }
    setSuggestionsLoading(false);
  };

  const handleImport = (data) => {
    setIsImported(true);
    const offers = data.payload
    console.log(offers)
    const offersData = offers.map((item, index) => {
      delete item.uuid;
      return {
      ...item,
      offer_type: item.offer_type.toLowerCase() === 'price ea' ? 'Price ea' :  item.offer_type,
      isValid: validateOffer(item),
      index,
    }});
    setOfferData(offersData);
  } 

  /*
  ///////////////////////////////////////
  ////// Form and Quickslection Changes ///////////////
  /////////////////////////////////////
  */

  const handleSuggestionClick = (value, event) => {
    if (value === "Manual") {
      setIsManualEntry(true);
    } else {
      if (activeField === "offer_type") {
        if (((value === 'Save' || value === 'save') && useDefaultSaveOfferText) || value !== 'Save') {
          setTimeout(() => {
            updateInfos({
              offer_text: INIT_OFFER_TEXT[value.toLowerCase()],
              offer_type: value,
            });
          }, 300);
        } else {
          updateInfos({
            offer_text: `save ${firstDefaultSaveOfferText}%`,
            offer_type: value,
          });
        }
      } else {
        updateActiveInfo(value);
      }
      moveNextField();
    }
  };

  /*
  ///////////////////////////////////////////////
  ////// Incentive Info Change //////////////////
  ///////////////////////////////////////////////
  */

  /*
  ///////////////////////////////////////
  ////// Key Commands //////////////////
  /////////////////////////////////////
  */

  // Handle key press for quick selection
  useKeypress(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"], (event) => {
    if (parseInt(event.key) <= currentSuggestions.length && !isManualEntry) {
      event.preventDefault();
      var value, title;

      //Any time the user clicks zero they are going to manual entry
      if (parseInt(event.key) === 0) {
        value = "Manual";
      } else {
        value = currentSuggestions[parseInt(event.key) - 1].value;
        title = currentSuggestions[parseInt(event.key) - 1].title;
      }

      handleSuggestionClick(value, title);
    }
  });

  //Disable manual entry by pressing escape
  useKeypress(["Escape"], (event) => {
    if (isManualEntry) {
      ////setInputFocused(false);
      setIsManualEntry(false);
    }
    if (document.activeElement) {
      document.activeElement.blur();
    }
  });

  //Remove Offers
  useKeypress(["-"], (event) => {
    // if (event.altKey) {
    //   handleRemoveOffer(event);
    // }
  });

  //Add Offers
  useKeypress(["+"], (event) => {
    if (event.altKey) {
      handleAddOffer();
    }
  });

  //Clone Offers
  useKeypress(["="], (event) => {
    // if (event.altKey) {
    //   handleCloneOffer();
    // }
  });

  //Cycle through offers
  useKeypress(["["], (event) => {
    event.preventDefault();
    if (event.shiftKey) {
      //If we go backwards to the start, go back to top.
      if (currentOfferIndex.value === 0) {
        currentOfferIndex.setValue(offerData.length - 1);
      } else {
        currentOfferIndex.setValue(currentOfferIndex.value - 1);
      }
    } else {
      //If we go forward to the end, go back to start.
      if (currentOfferIndex.value === offerData.length - 1) {
        currentOfferIndex.setValue(0);
      } else {
        currentOfferIndex.setValue(currentOfferIndex.value + 1);
      }
    }
  });

  //Approve offers
  useKeypress(["q", "Q"], (event) => {
    if (event.altKey) {
      if (!(isFetching || isSubmitting || isSubmitDisabled || isOfferAndIncentiveEmpty)) {
        handleApprove();
      }
    }
  });

  //DNE offers
  useKeypress(["x", "X"], (event) => {
    if (event.altKey) {
      handleOpenModal();
    }
  });

  //SKIP offers--
  useKeypress(["e", "E"], (event) => {
    if (event.altKey) {
      handleSkipAdblock();
    }
  });

  //REJECT offers
  useKeypress(["r", "R"], (event) => {
    if (event.altKey) {
      handleReject();
    }
  });

  //refresh Suggestions
  useKeypress(["t", "T"], (event) => {
    if (event.altKey) {
      refreshAdblockSuggestions();
    }
  });

  //Move to next field item, only if not in manual entry
  useKeypress(["Tab"], (event) => {
    event.preventDefault();
    if (event.shiftKey) {
      movePreviousField();
    } else {
      moveNextField();
    }
  });

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout>
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <div className="my-auto d-flex">
                  <>
                    <Link href="/circular" className="ml-1 my-auto">
                      Circulars
                    </Link>
                    <Link
                      href={`/circular/${currentCircularId}/${currentPageId}/${currentAdBlockId}`}
                      className="ml-2 my-auto"
                    >
                      {circularName}
                    </Link>
                  </>

                <PageDropDown
                  className="ml-3"
                  id={currentCircularId}
                  onSelectValue={onPageSelect}
                  value={currentPageId}
                  isVisible={userInfo?.user?.is_superuser || userInfo?.user?.is_moderator|| userInfo?.user?.is_active}
                />
                <PageType className="ml-2 my-auto">
                    {pageType}
                  </PageType>
                <AdBlockDropDown
                  className="ml-3"
                  id={currentPageId}
                  onSelectValue={handleAdblockSelect}
                  value={currentAdBlockId}
                  isVisible={userInfo?.user?.is_superuser || userInfo?.user?.is_moderator || userInfo?.user?.is_active}
                  adblockData={adblockData}
                  onLoad={onAdblockDataLoad}
                  disabled={isFetching}
                />
              </div>

              <PageInfo
                className="ml-auto my-auto "
                pageData={pageData}
                adblockData={adblockData}
              />
              {(!isLoading && currentAdblockData.similar_adblock_uuid && !currentAdblockData.has_children && !currentAdblockData.offers_approved) &&
                <Button
                  // buttonTheme={"dark"}
                  width="50px"
                  size="small"
                  className="ml-2 my-auto"
                  onClick={() => imageMatchingModal.openModal()}
                  isLoading={isSubmitting}
                  disabled={isFetching}
                  eventType="flag"
                >
                  <BsImageAlt />
                </Button>
              }
              <FlagButtonGroup
                isSubmitting={isSubmitting}
                isFetching={isSubmitting}
                onDoNotEnter={handleOpenModal}
                onSkip={handleSkipAdblock}
                onReject={handleReject}
                onApprove={handleApprove}
                onNext={navigateNext}
                isDoNotEnter={isDoNotEnter}
                isSkipped={isSkipped}
                isRejected={isRejected}
                isApproved={isApproved}
                isAllOfferValid={allOffersValid}
                isSubmitDisabled={isSubmitDisabled || isOfferAndIncentiveEmpty}
                ShowNext={true}
              />
            </div>
          )}
        </Header>

        <Content>
          <div className="d-flex flex-column" style={{ width: "320px" }}>
            <AdblockLocation
              backImg={pageImg && `data:image/jpeg;base64,${pageImg}`}
              pageWidth={pageWidth}
              pageHeight={pageHeight}
              adblockCoords={adblockCoords}
              sectionCoords={sectionCoords}
            />
            <MetadataSection title="ADBLOCK DATA">
              <EventsDropDown
                pageId={currentPageId}
                {...events}
                onRefresh={refetchAdblocks}
                onChange={(e) => events.setValue(e)}
              />
              <MetaData className="mt-3" />
            </MetadataSection>
          </div>
          <AdblockImage
            type="offer"
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            blockRef={blockRef}
            isFetching={isFetching}
            filename={adblockFilename}
            currentAdBlockData={currentAdblockData}
            circularId={currentCircularId}
            pageId={currentPageId}
            sizeData={sizeData}
          />
          <div className="d-flex flex-column">
            <OfferSelector
              title={`OFFERS & INCENTIVES ${
                currentOfferIndex.value +
                1 +
                (currentItemType === "incentive" ? currentInfo.index + 1 : 0)
              }/${offerData.length + incentiveData.length}`}
              max={offerData.length && productData.length}
              onChange={handleSplit}
              value={split}
              productData={productData}
              offerData={offerData}
              incentiveData={incentiveData}
              onAdd={handleAddItem}
              disableSubmit={(value) => setIsSubmitChildDisabled(value)}
              isAddable
            >
              {offerData.length === 0 && incentiveData.length === 0 ? (
                <EmptyOfferSelector
                  onCreateOffer={handleAddOffer}
                  onCreateIncentive={handleAddIncentive}
                />
              ) : (
                <div className="d-flex">
                  <ItemsList>
                    {offerData.map((offer, index) => (
                      <OfferItem
                        key={`offer${index}`}
                        className="d-flex flex-row"
                        active={
                          index === currentOfferIndex.value &&
                          currentItemType === "offer"
                        }
                        onClick={() => handleOfferSelect(index)}
                        onRemove={handleRemoveOffer}
                        onClone={handleCloneOffer}
                        data={offer}
                        index={index}
                      />
                    ))}
                    {incentiveData.map((incentive, index) => (
                      <IncentiveItem
                        key={`incentive${index}`}
                        className="d-flex flex-row"
                        active={
                          index === currentInfo.index &&
                          currentItemType === "incentive"
                        }
                        onClick={() => handleIncentiveSelect(index)}
                        onRemove={handleRemoveIncentive}
                        onClone={(event, index) => handleCloneIncentive(event, index)}
                        data={incentive}
                        index={index}
                        incentiveData={incentiveData}
                        isApproved={isApproved}
                      />
                    ))}
                  </ItemsList>
                </div>
              )}
            </OfferSelector>
            <div
              className="d-flex flex-1"
              style={{ height: "calc(100% - 195px)" }}
            >
              <QuickSelection
                onRefreshSuggestion={() => refreshAdblockSuggestions()}
                isRefresh={adblockData.length > 0}
              >
                {isManualEntry && !isApproved && (
                  <ManualEntry onExit={() => setIsManualEntry(false)} />
                )}
                {((activeField === "offer_text" && isManualEntry) ||
                  (activeField === "offer_text" &&
                    currentSuggestions.length === 0)) && (
                  <OfferTextBuilder
                    type={currentInfo?.offer_type}
                    value={currentInfo?.offer_text}
                    existingOfferTextForSave={firstDefaultSaveOfferText}
                    onChange={(e) => {
                      updateInfos({
                        offer_text: e,
                        qty_off_total_purchase_f: e.match(' each') ? false : true
                      });
                    }}
                    onStartChange={(e) => {
                      if (e) {
                        if (pageType === "Coupon Page" || digitalCouponAppliedRef.current) {
                          const tempMetaData = metaData.map(a => { return { ...a } })
                          tempMetaData.find(a => a.key === "coupon_f").value = true;
                          tempMetaData.find(a => a.key === "coupon_f").coupon_amnt = e;
                          setMetaData(tempMetaData)
                        }
                      }
                    }}
                  />
                  )}
                {((activeField === "incentive_text" && isManualEntry) ||
                  (activeField === "incentive_text" &&
                    currentSuggestions.length === 0)) && (
                  <IncentiveTextBuilder
                    type={currentInfo?.incentive_type}
                    value={currentInfo?.incentive_text}
                    onChange={(e) => {
                      updateInfo("incentive_text", e);
                    }}
                  />
                  )}
                {
                  //TODO: this part should have its own loading state
                  isFetching || suggestionsLoading ? (
                    <div>Loading...</div>
                  ) : offerData.length === 0 && incentiveData.length === 0 ? (
                    <div className="mt-4 pt-4 text-center">
                      No offer/incentive available
                    </div>
                  ) : (
                    <>
                      <div className="text-blue-light mb-3">
                        {activeOfferInfoText}
                      </div>

                      {isApproved ? (
                        <AdblockApproved />
                      ) : currentSuggestions &&
                        currentSuggestions.length > 0 ? (
                        <>
                          {!isManualEntry && (
                            <QuickSelectionChoice
                              index={0}
                              data={{
                                title: "Manual Entry",
                                value: "Manual",
                              }}
                              active={false}
                              showIndex={!isManualEntry}
                              onClick={handleSuggestionClick}
                            />
                          )}

                          {currentSuggestions
                            .slice(
                              0,
                              isManualEntry ? currentSuggestions.length : 9
                            )
                            .filter((item) => item.title)
                            .map((item, index) => (
                              <QuickSelectionChoice
                                key={`${index}key`}
                                showIndex={!isManualEntry}
                                index={index + (isManualEntry ? 0 : 1)}
                                data={item}
                                active={activeFieldValue === item.value}
                                onClick={handleSuggestionClick}
                              />
                            ))}
                        </>
                      ) : (
                        circularType === 'Training Circular' ?
                        <div
                            className="mt-4 text-center"
                            style={{
                              paddingTop: '180px',
                              color: '#aaa',
                              fontSize: '14px',
                            }}
                          >
                            <h3>Training Circular</h3>
                            <div className="mt-3">
                              Suggestions has been disabled for this circular.
                            </div>
                          </div> :
                          <>
                            <NoSuggestions />
                          </>

                      )}
                    </>
                  )
                }
              </QuickSelection>

              {currentItemType === "offer" ? (
                <OfferInfo
                  key={'offerinfo-'+currentOfferIndex.value} 
                  offerTypeData={offerTypeData} 
                  useDefaultSaveOfferText={useDefaultSaveOfferText} 
                  isLinerDisabled={isLinerDisabled}
                />
              ) : currentItemType === "incentive" ? (
                <IncentiveInfo />
              ) : (
                <EmptyInfo />
              )}
            </div>
          </div>
        </Content>
        <EndQueueModal
          {...endQueueModal}
          circularId={currentCircularId}
          goto="circular"
        />
        <OfferDeletionModal
          {...deleteOfferModal}
          adblockId={currentAdBlockId}
          pageId={currentPageId}
          onDelete={async (data) => {
            setOfferData(offerData.filter(item => item.uuid !== data));
            fetchAdBlock(currentAdBlockId, true);
          }}
          deletingOffer={deletingOffer}
        />
        {/* {imageMatchingModal.open &&  */}
          <ImageMatchingModal
            {...imageMatchingModal}
            currentAdBlockData={currentAdblockData}  
            circularName={circularName}
            dupe_block={currentAdblockData.similar_adblock_uuid}
            onSuccess={navigateNext}
            startTime={startTime}
            onImport={(data) => handleImport(data)}
            onError={(err) => toast.error(err)}
          />
        {/* } */}
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          msgBody={confirmationMessage}
          onContinue={handleContinue}
          onCancel={handleCancelConfirmation}
        ></ConfirmationModal>
      </MainLayout>
      <ToastContainer />
    </SkeletonTheme>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;

const PageType = styled.div`
  padding: 5px;
`;

const MetadataSection = styled(Card)`
  min-width: 240px;
  flex: 1;
  overflow:auto;
`;

const Header = styled.div`
  height: 75px;
  color: white;
  font-size: 14px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;

const ItemsList = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 120px;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
