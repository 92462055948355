import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FaPlus } from "react-icons/fa";
import { useMainContext } from "contexts";
import {
  CheckBox,
} from "components";
import { AiFillWarning } from "react-icons/ai";

export const OfferSelector = ({
  title,
  children,
  className,
  max,
  value,
  productData,
  offerData,
  incentiveData,
  onAdd = () => {},
  disableSubmit = () => {},
  isAddable = false,
  onChange = () => {} 
}) => {

  const containerRef = useRef(null);
  const { postPageEvents } = useMainContext();
  const [isLinerSelected, setIsLinerSelected] = useState(false);
  const [isNonLinerSelected, setIsNonLinerSelected] = useState(false);
  
  const handleAdd = (params) => {
    containerRef.current && containerRef.current.blur();
    onAdd(params);
    postPageEvents("click", `New ${params}`);
  };

  const onInputChange = (value) => {
    onChange(value);
   };

   useEffect(() => {
    if (productData.length > 0){
      const hasProductLiner = productData.some(item => item?.liner_f === true);
      const hasProductNonLiner = productData.some(item => item?.liner_f === false);
      
      let linerSelected = false;
      let nonLinerSelected = false;
      const filteredIncentiveData = incentiveData.filter(item => item?.incentive_attach_to === 'Adblock');
      if (offerData.length > 0 || filteredIncentiveData.length > 0) {
        const hasOfferLiner = offerData.some(item => item?.liner_f === true);
        const hasOfferNonLiner = offerData.some(item => item?.liner_f === false);
        const hasIncentiveLiner = filteredIncentiveData.some(item => item?.incentive_liner_f === true);
        const hasIncentiveNonLiner = filteredIncentiveData.some(item => item?.incentive_liner_f === false);
  
        if (hasProductLiner && hasProductNonLiner) { // Mixed case
          linerSelected = !hasOfferLiner && !hasIncentiveLiner;
          nonLinerSelected = !hasOfferNonLiner && !hasIncentiveNonLiner;
        } else if (hasProductLiner) { // All liners
          linerSelected = !(offerData.every(item => item?.liner_f === true) && filteredIncentiveData.every(item => item?.incentive_liner_f === true));  
        } else if (hasProductNonLiner) { // All non-liners
          nonLinerSelected = hasOfferLiner || hasIncentiveLiner;  
        }
      } else { 
        if (hasProductLiner && hasProductNonLiner) { 
          linerSelected = true; 
          nonLinerSelected = true; 
        } else if (hasProductLiner) {
          linerSelected = true;
        } else if (hasProductNonLiner) {
          nonLinerSelected = true;
        }
      }
  
      disableSubmit(linerSelected || nonLinerSelected);
      setIsLinerSelected(linerSelected);
      setIsNonLinerSelected(nonLinerSelected);
    }
  }, [productData, offerData, incentiveData])

  return (
    <Container className={className}>
      <Header>
        <Title>{title}</Title>
        { max || value ? (
          <Split>
            <CheckBox 
            value={value}
            onChange={onInputChange}
            >Split</CheckBox>
          </Split>
        ): null }
      
        { (isLinerSelected && isNonLinerSelected) &&  // Mixed case has highest priority
          <LinerWarning>
            <AiFillWarning className={'warning'} />
            <span>OFFERS MUST HAVE AT-LEAST ONE LINER AND ONE NON-LINER</span> 
          </LinerWarning>
        }
        { (isLinerSelected && !isNonLinerSelected) &&  // Then check for all liners
          <LinerWarning>
            <AiFillWarning className={'warning'} />
            <span>ALL/ATLEAST(1) OFFER MUST HAVE A LINER</span> 
          </LinerWarning>
        }
        { (isNonLinerSelected && !isLinerSelected) &&  // Finally, check for all non-liners
          <LinerWarning>
            <AiFillWarning className={'warning'} />
            <span>ALL/ATLEAST(1) OFFERS MUST BE WITHOUT LINER</span> 
          </LinerWarning>
        }
      
        {isAddable && (
          <Button className="d-flex ml-auto" ref={containerRef} tabIndex={0}>
            <div className="menu-button">
              <FaPlus />
            </div>
            <div className="menu-dropdown">
              <div
                className={"menu-item"}
                onClick={() => handleAdd("incentive")}
              >
                Add Incentive
              </div>
              <div className={"menu-item"} onClick={() => handleAdd("offer")}>
                Add Offer
              </div>
            </div>
          </Button>
        )}
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

const Title = styled.div`
  width: auto;
`;

const Container = styled.div`
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  color: white;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  height:25px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: calc(100% - 12px);
`;

const Button = styled.div`
  margin-top: -8px;
  position: relative;
  display: flex;
  padding: 0;
  outline: 0;
  text-align: left;
  height: 32px;
  width: 32px;
  .menu-button {
    line-height: 32px;
    border-radius: 0px;
    color: white;
    cursor: pointer;
    min-width: 32px;
    height: 32px;
    line-height: 32px;
    margin: auto 0;
    padding: 0px 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 16px;
    transition: all 300ms ease;
    display: flex;
    font-size: 12px;
    &:hover {
      opacity: 0.7;
    }
    svg {
      margin: auto;
      width: 12px;
    }
    &__drop-down {
      width: 8px;
      transition: all 300ms ease;
      margin: auto 0 auto 8px;
    }
    &:hover {
      background: #2b2b2b;
    }
  }

  &:focus .menu-button {
    pointer-events: none;
    background: #2b2b2b;
  }

  /* If :focus detected the dropdown menu is displayed by making it visible */
  &:focus .menu-dropdown {
    opacity: 1;
    visibility: visible;
  }
  &:focus .menu-button__drop-down {
    transform: rotate(180deg);
  }

  /* style for the dropdown box */
  .menu-dropdown {
    width: 100%;
    margin: 3px 0 0 0;
    padding: 4px 0;
    pointer-events: auto;
    position: absolute;
    z-index: 2;
    opacity: 0;
    left: 0px;
    top: 32px;
    visibility: hidden;
    // transition: all 300ms ease;
    color: white;
    background: #2b2b2b;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    max-height: 220px;
    overflow: auto;
    width: 160px;
    margin-left: -128px;
    .hidden {
      visibility: hidden;
    }
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 14px;
      background: ${(props) => props.theme.palette.backgrounds};
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${(props) => props.theme.palette.backgrounds};
      border: 1px solid ${(props) => props.theme.palette.itemsHover};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.palette.itemsHover};
      cursor: pointer;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
      cursor: pointer;
    }
  }

  /* style the links in the dropdown */
  .menu-dropdown > .menu-item {
    border: none;
    outline: 0;
    display: block;
    color: white;
    background: #2b2b2b;
    text-decoration: none;
    padding: 0 12px;
    height: 24px;
    line-height: 24px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    transition: all 300ms ease;
    &:hover {
      background: #191919;
      border-radius: 0px;
      color: white;
    }
    &.active::after {
      content: " ";
      position: absolute;
      width: 12px;
      height: 6px;
      border-left: 2px solid #3bc7e3;
      border-bottom: 2px solid #3bc7e3;
      transform: rotate(-45deg);
      top: 11px;
      transition: all 300ms ease;
      right: 12px;
    }
  }
`;

const Split = styled.div`
  border-left:1px solid ${(props) => props.theme.palette.secondary};
  display:flex;
  padding:0 10px;
  margin-left:15px;
  div {
    padding:0 5px;
  }

  label {
    padding-left: 24px;
    margin: 4px 0px 2px 0;
  }
`;

const LinerWarning = styled.div`
  border-left:1px solid ${(props) => props.theme.palette.secondary};
  display: flex;
  align-items: center;
  marign-left:15px;
  svg{
    margin: 0 8px 0 15px;
    font-size: 16px;
    transform: scale(1.2);
    color: ${(props) => props.theme.palette.warning}
  }
  span{
    font-size: 12px;
    line-break: anywhere;
  }
`;
