import { React, useState, useEffect } from "react";
import styled from "styled-components";
import { useInput } from "hooks";
import { InlineDateRange, Button, DropDown2 } from "components";
import { SearchByRetailerName } from "components/common/SearchByRetailerName";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useRetailersContext } from "contexts";
import {
  getPageCroppingStats,
  getAblockTimingStats,
  //getRetailerTimingStats,
  getUserRollupStats,
  getWeeklySummaryStats,
  getAllUsers,
  getTiers,
  getRetailerAutocompleteApi,
  getRetailerVolumeReport,
  getRetailerVolumeTierWiseReport,
  getCircularStats,
  getAdBlockProductivityStats,
  getCounterStatAPI,
  getAdBlockActivityStats,
  getUserProductivityAPI,
  getBlockProductCategoryStatAPI
} from "api";
import { ToastContainer, toast } from 'react-toastify';

import "rc-slider/assets/index.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import 'react-toastify/dist/ReactToastify.css';
import { circular_types } from "utils";

export const ReportsSection = ({ filterOptions, isLoading, onChange }) => {
  const dateFrom = useInput("");
  const dateTo = useInput("");
  const retailerSearch = useInput("");
  // const adblockTimeDateFrom = useInput("");
  // const adblockTimeDateTo = useInput("");
  // const retailerTimeDateFrom = useInput("");
  // const retailerTimeDateTo = useInput("");
  // const weeklyDateFrom = useInput("");
  // const weeklyDateTo = useInput("");
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [reportType, setReportType] = useState('adblock_timing');
  const [tiers, setTiers] = useState([]);
  const [tier, setTier] = useState('');
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState('');
  const [retailer, setRetailer] = useState('');
  const [circularType, setCircularType] = useState('');

  const {
    findRetailer
  } = useRetailersContext();

  const report_types = [
    { id: "adblock_timing", title: "Adblock Timing" },
    { id: "page_cropping", title: "Page Cropping" },
    { id: "weekly_summary", title: "Weekly Summary" },
    { id: "users_rollup", title: "Users Rollup" },
    { id: "retailer_volume", title: "Retailer Volume"},
    { id: "retailer_volume_tier_wise", title: "Retailer Volume Tier Wise"},
    { id: "incomplete_circular", title: "Incomplete Circular Stat"},
    { id: "completed_circular", title: "Completed Circular Stat"},
    { id: "productivity_cropped", title: "AdBlock Productivity Stat (Cropped)"},
    { id: "productivity_entry", title: "AdBlock Productivity Stat (Entry Process)"},
    { id: "productivity_dedupe", title: "AdBlock Productivity Stat (DeDupe Process)"},
    { id: "counter", title: "Counter Stat"},
    { id: "adblock_skip", title: "Adblock Skip" },
    { id: "user_activity", title: "User Activity" },
    { id: "block_product_category", title: "Block Product Category" },
  ];

  const init = async () => {
    const resultsTiers = await getTiers();
    if(resultsTiers.success) {

      //console.log('get tiers', resultsTiers.results);

       const tiersMapped = resultsTiers.results.tiers.map((item) => ({
          ...item,
          title: item.ext_id,
          id: item.ext_id
        }))

        setTiers(tiersMapped);

    }else{
        //Raise Error
    }

    const resultsUsers = await getAllUsers({
      per_page: 500,
      page: 0,
      sort_by: 'username',
      sort_dir: 'ASC',
    });
    if(resultsUsers.success) {

      //console.log('get user', resultsUsers.payload);

       const usersMapped = resultsUsers.payload.users.map((item) => ({
          ...item,
          title: item.username,
          id: item.id
        }))

        setUsers(usersMapped);

    }else{
        //Raise Error
    }

  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const updateReportType = (type) => {
    setReportType(type);
  }

  const handleDownload = (data) => {
    if(reportType === 'adblock_timing') {
      generateAdblockTimeReport();
    }else if(reportType === 'page_cropping') {
      generateCropPageReport();
    }else if(reportType === 'weekly_summary'){
      generateWeeklySummaryReport();
    }else if(reportType === 'users_rollup'){
      generateUsersRollupReport();
    }else if(reportType === 'retailer_volume'){
      generateReportVolumeReport(data);
    }else if(reportType === 'retailer_volume_tier_wise'){
      generateReportVolumeTierWiseReport(data);
    }else if(reportType === 'incomplete_circular'){
      generateCircularReport('INCOMPLETE');
    }else if(reportType === 'completed_circular'){
      generateCircularReport('COMPLETE');
    }else if(reportType === 'productivity_cropped'){
      generateAdBlockProductivityStats('productivity_cropped');
    }else if(reportType === 'productivity_entry'){
      generateAdBlockProductivityStats('productivity_entry');
    }else if(reportType === 'productivity_dedupe'){
      generateAdBlockProductivityStats('productivity_dedupe');
    }else if(reportType === 'counter'){
      generateCounterReport();
    } else if(reportType === 'adblock_skip') {
      generateAdBlockActivityReport();
    } else if (reportType === 'user_activity') {
      generateUserActivityReport();
    } else if (reportType === 'block_product_category') {
      generateBlockProductCategoryStats();
    }
    else{
      //Throw Error
    }
  }

  const generateCropPageReport = async () => {
    setIsGeneratingReport(true);

    let result = await getPageCroppingStats(
      dateFrom.value,
      dateTo.value,
      "https://" + window.location.hostname,
      tier ? tier : null,
      user ? user : null,
      retailer ? retailer : null
    );
    if (result.success) {
      // Update adblocks
      const url = window.URL.createObjectURL(
        new Blob([result.results], { type: "text/plain" })
      );
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = `page_crop_timings_${dateFrom.value}-${dateTo.value}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      //TODO: Show error message
    }

    setIsGeneratingReport(false);
  };

  const generateAdblockTimeReport = async () => {
    setIsGeneratingReport(true);
    let result = await getAblockTimingStats({
      fromDate: dateFrom.value,
      toDate: dateTo.value,
      domain_url: "https://" + window.location.hostname,
      tier_id: tier,
      user_id: user,
      retailer_id: retailer,
    });
    if (result.success) {
      toast.info(result.results.message, {
        position: "top-right", autoClose: 8000, hideProgressBar: true, closeOnClick: true,
        pauseOnHover: true, progress: undefined, theme: "light"
      });
    //   // Update adblocks
    //   const url = window.URL.createObjectURL(
    //     new Blob([result.results], { type: "text/plain" })
    //   );
    //   const a = document.createElement("a");
    //   a.style.display = "none";
    //   a.href = url;
    //   // the filename you want
    //   a.download = `adblock_timings_${dateFrom.value}-${dateTo.value}.csv`;
    //   document.body.appendChild(a);
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    } else {
      toast.error('Something went wrong!', {
        position: "top-right", autoClose: 5000, hideProgressBar: true, closeOnClick: true,
        pauseOnHover: true, progress: undefined, theme: "light"
      });
    }

    setIsGeneratingReport(false);
  };

  const generateWeeklySummaryReport = async () => {
    setIsGeneratingReport(true);

    let result = await getWeeklySummaryStats(
      dateFrom.value,
      dateTo.value
    );
    if (result.success) {
      // Update adblocks
      const url = window.URL.createObjectURL(
        new Blob([result.results], { type: "text/plain" })
      );
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = `retailer_timings_${dateFrom.value}-${dateTo.value}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      //TODO: Show error message
    }

    setIsGeneratingReport(false);
  };

  const generateUsersRollupReport = async () => {
    setIsGeneratingReport(true);

    let result = await getUserRollupStats(
      dateFrom.value,
      dateTo.value
    );
    if (result.success) {
      // Update adblocks
      const url = window.URL.createObjectURL(
        new Blob([result.results], { type: "text/plain" })
      );
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = `users_rollup_${dateFrom.value}-${dateTo.value}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      //TODO: Show error message
    }

    setIsGeneratingReport(false);
  };

  const generateCircularReport = async (circular_stage) => {
    setIsGeneratingReport(true);
    
    let result = await getCircularStats(
      dateFrom.value,
      dateTo.value,
      circular_stage,
      circularType ? circular_types.find(item => item.id === circularType).title : ''
    );
    if (result.success) {
      // Update adblocks
      const url = window.URL.createObjectURL(
        new Blob([result.results], { type: "text/plain" })
      );
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = `${circular_stage}_circulars_${dateFrom.value}-${dateTo.value}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      //TODO: Show error message
    }

    setIsGeneratingReport(false);
  };

  const generateAdBlockProductivityStats = async (action) => {
    setIsGeneratingReport(true);
    let circ_ = ''
    if (circularType) {
      circ_ = circular_types.find(item => item.id === circularType).title
      console.log(circ_, circular_types)
    }
    let result = await getAdBlockProductivityStats(
      //for productivity reports pass only date
      dateFrom.value.substring(0, dateFrom.value.indexOf(" ")), 
      dateTo.value.substring(0, dateFrom.value.indexOf(" ")),
      circ_ ? circ_ : '',
      action
    );
    if (result.success) {
      toast.info(result.results.message, {
        position: "top-right", autoClose: 8000, hideProgressBar: true, closeOnClick: true,
        pauseOnHover: true, progress: undefined, theme: "light"
      });
    } else {
      toast.error('Something went wrong!', {
        position: "top-right", autoClose: 5000, hideProgressBar: true, closeOnClick: true,
        pauseOnHover: true, progress: undefined, theme: "light"
      });
    }

    setIsGeneratingReport(false);
  };

  const generateReportVolumeReport = async (data) => {
    setIsGeneratingReport(true);
    let result;
    let filename = ''
    console.log(dateFrom, dateTo)
    if (data !== 'six_week_data') {
      result = await getRetailerVolumeReport({
        fromDate: dateFrom.value,
        toDate: dateTo.value,
      });
      filename = `retailer_volume_${dateFrom.value}-${dateTo.value}.csv`;
    } else {
      result = await getRetailerVolumeReport({
        fromDate: null,
        toDate: null,
      });
      filename = `retailer_volume_6_week_report`;
    }
    if (result.success) {
      // Update adblocks
      const url = window.URL.createObjectURL(
        new Blob([result.results], { type: "text/plain" })
      );
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = `${filename}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      //TODO: Show error message
    }

    setIsGeneratingReport(false);
  };

  const generateReportVolumeTierWiseReport = async (data) => {
    setIsGeneratingReport(true);
    let result;
    let filename = ''
    console.log(data, dateFrom, dateTo)
    if (data !== 'six_week_data') {
      result = await getRetailerVolumeTierWiseReport({
        fromDate: dateFrom.value,
        toDate: dateTo.value,
      });
      filename = `retailer_volume_tier_wise_report_${dateFrom.value}-${dateTo.value}`;
    } else {
      result = await getRetailerVolumeTierWiseReport({
        fromDate: null,
        toDate: null,
      });
      filename = `retailer_volume_tier_wise_report_6_week`;
    }
    console.log(result)
    if (result.success) {
      const url = window.URL.createObjectURL(
        new Blob([result.results], { type: "text/plain" })
      );
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${filename}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      //TODO: Show error message
    }

    setIsGeneratingReport(false);
  };

  const generateCounterReport = async () => {
    setIsGeneratingReport(true);

    let result = await getCounterStatAPI(
      dateFrom.value,
      dateTo.value
    );
    if (result.success) {
      console.log(result)
      const url = window.URL.createObjectURL(
        new Blob([result.results], { type: "text/plain" })
      );
      console.log(url)
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `counter.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      //TODO: Show error message
    }

    setIsGeneratingReport(false);
  };

  const generateAdBlockActivityReport = async () => {
    setIsGeneratingReport(true);
    let result = await getAdBlockActivityStats(
      dateFrom.value,
      dateTo.value
    );
    if (result.success) {
      console.log(result)
      const url = window.URL.createObjectURL(
        new Blob([result.results], { type: "text/plain" })
      );
      console.log(url)
      const filename = `skip_adblock_report_${dateFrom.value}-${dateTo.value}`;
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${filename}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      //TODO: Show error message
    }

    setIsGeneratingReport(false);
  };

  const generateUserActivityReport = async () => {
    setIsGeneratingReport(true);
    let result = await getUserProductivityAPI(
      dateFrom.value,
      dateTo.value
    );
    if (result.success) {
      console.log(result)
      const url = window.URL.createObjectURL(
        new Blob([result.results], { type: "text/plain" })
      );
      console.log(url)
      const filename = `user_activity_report_${dateFrom.value}-${dateTo.value}`;
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${filename}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      //TODO: Show error message
    }

    setIsGeneratingReport(false);
  };

  const generateBlockProductCategoryStats = async () => {
    setIsGeneratingReport(true);
    let result = await getBlockProductCategoryStatAPI(
      dateFrom.value,
      dateTo.value
    );
    if (result.success) {
      toast.info(result.results.message, {
        position: "top-right", autoClose: 8000, hideProgressBar: true, closeOnClick: true,
        pauseOnHover: true, progress: undefined, theme: "light"
      });
    } else {
      toast.error('Something went wrong!', {
        position: "top-right", autoClose: 5000, hideProgressBar: true, closeOnClick: true,
        pauseOnHover: true, progress: undefined, theme: "light"
      });
    }
    setIsGeneratingReport(false);
  };

  const handleSearch = async (params) => {
    if(params === "") {
      //getRetailers();
    }else{
      findRetailer(params);
    }
  };

  const handleSelectRetailer = (params) => {
    console.log('retailer', params);
    setRetailer(params);
  };

  const onTierUpdate = async (e) => {
    setTier(e);
  };

  const onUserUpdate = async (e) => {
    setUser(e);
  };

  return (
    <AccordionSection>
      {isLoading ? (
        <SkeletonTheme color="#202020" highlightColor="#444">
          <Skeleton count={16} height={24} className="my-2" />
        </SkeletonTheme>
      ) : (
        <>

          <div className='d-flex'>

            <LeftForm>
              <InlineDateRange
                  open={dateFrom || dateTo}
                  startDate={
                    dateFrom.value !== ""
                      ? dateFrom.value
                      : undefined
                  }
                  endDate={
                    dateTo.value !== "" ? dateTo.value : undefined
                  }
                  onChange={(dates) => {
                    dateFrom.setValue(dates[0]);
                    dateTo.setValue(dates[1]);
                  }}
                />

            </LeftForm>

            <RightForm>
              <DropDown2
                  label="Report Type"
                  data={report_types}
                  width={120}
                  value={reportType}
                  onChange={(e) => {
                    updateReportType(e);
                  }}
                />

                {reportType === 'adblock_timing' || reportType === 'page_cropping' ? 
                  <>
                    <RetailerContainer>
                    <SearchByRetailerName
                      isLoading={isLoading}
                      func={getRetailerAutocompleteApi}
                      onSearch={handleSearch}
                      onSelect={handleSelectRetailer}
                      {...retailerSearch}
                      searchButton={false}
                      borderBottom={false}
                    />
                  </RetailerContainer>

                  <DropDoownsContainer>
                    <DropDown2
                      label="Select Tier"
                      data={tiers}
                      width={120}
                      value={tier}
                      onChange={(e) => {onTierUpdate(e);}}
                    />

                    <DropDown2
                      label="Select User"
                      data={users}
                      width={320}
                      value={user}
                      onChange={(e) => {onUserUpdate(e);}}
                    />
                  </DropDoownsContainer>
                </>
              
              : reportType === 'retailer_volume' || reportType === 'retailer_volume_tier_wise' ? 
              <>
                <ButtonContainer>
                  <Button
                    buttonTheme="primary"
                    size="small"
                    className="mt-auto my-auto"
                    onClick={() => handleDownload('six_week_data')}
                    isLoading={isGeneratingReport}
                  >
                    Download For Past 6 Weeks From Today
                  </Button>
                </ButtonContainer>
              </> 
              : (reportType === 'productivity_cropped' || reportType === 'productivity_entry' || reportType === 'productivity_dedupe' || reportType === 'incomplete_circular' || reportType === 'completed_circular') ?
              <>
                <DropDoownsContainer>
                  <DropDown2
                    label="Select Circular Type"
                    data={circular_types}
                    width={320}
                    value={circularType}
                    onChange={(e) => { setCircularType(e); }}
                  />
                  {/* <DropDown2
                    label="Select Tier"
                    data={tiers}
                    width={120}
                    value={tier}
                    onChange={(e) => { onTierUpdate(e); }}
                  /> */}
                </DropDoownsContainer>
              </>
              :null}

                <ButtonContainer>
                  <Button
                    buttonTheme="dark"
                    size="small"
                    className="mt-auto my-auto"
                    onClick={() => handleDownload()}
                    disabled={!dateFrom.value && !dateTo.value}
                    isLoading={isGeneratingReport}
                  >
                    Download Report
                  </Button>
                </ButtonContainer>
            </RightForm>
          </div>
          <ToastContainer />
        </>
      )}
    </AccordionSection>
  );
};

const AccordionSection = styled.div`
  margin: 12px 12px 12px 24px;
  padding-right: 10px;
  overflow: auto;
  min-width: 240px;
  flex: 1;
  ::-webkit-scrollbar {
    width: 10px;
    background: ${(props) => props.theme.palette.background};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.background};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }

  .title {
    color: white;
    font-size: 12px;
    border-bottom: 1px solid ${(props) => props.theme.palette.itemsHover};
    text-transform: uppercase;
  }
`;

const LeftForm = styled.div`
  padding:20px;
`;

const RightForm = styled.div`
  margin-left:auto;
  flex:1;
  padding:20px;
  .justify-content-right {
    justify-content: right;
  }
`;

const DropDoownsContainer = styled.div`
  display:flex;
  padding:20px;
  div  {
    margin-left:10px;
    div {
      margin:0;
    }
  }
`;

const RetailerContainer = styled.div`
  padding:20px;
`;

const ButtonContainer = styled.div`
  padding:20px;
`;
