import { API, PEP_API } from "utils";
import { Buffer } from 'buffer';

// DEPRECATED: get images from S3 via EC2
// export const getImage = async (id) => {
//   try {
//     const response = await API({}, {}, { timeout: 60 }).get(`api/files/images/${id}`, {
//       responseType: "arraybuffer",
//     });
//     return {
//       success: true,
//       payload: Buffer.from(response.data, "binary").toString("base64"),
//     };
//   } catch (e) {
//     return { success: false, payload: [], error: e };
//   }
// };

export const getImage = async (id) => {
  try {
    // fetch the pre-signed URL from the backend
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).get(`api/files/images-signed/${id}`);

    if (response.data && response.data.url) {
      // If a pre-signed URL is there, fetch the image using it
      const imageResponse = await fetch(response.data.url); // ReadableStream
      if (!imageResponse.ok) {
        throw new Error("Failed to fetch image");
      }

      // Convert the response to an ArrayBuffer
      const arrayBuffer = await imageResponse.arrayBuffer();

      // Convert ArrayBuffer to base64
      const base64 = Buffer.from(arrayBuffer, "binary").toString("base64");

      return {
        success: true,
        payload: `${base64}`
      };
    } else {
      return { success: false, payload: [], error: 'Pre-signed URL not found' };
    }
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
