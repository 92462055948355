import React, { useState } from "react";
import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "@reach/router";
import { MenuDropDown } from ".";
import {
  resetAdblock,
  copyAllData
} from "api";
import { navigate } from "@reach/router";
import { useModal } from "hooks";
import { ViewSectionModal } from "modals";
import { splitIncentives, formatDateTime } from "utils"

const KEY_MAP = {
  bulkqty_f: "Bulk Quantity",
  newitem_f: "New Item",
  select_varieties_f: "Selected Varieties",
  bonus_card_f: "Bonus Card",
  brand_equity_f: "Brand Equity",
  coupon_f: "Coupon",
  rebate_f: "Rebate",
  inc_discount_f: "Inc Discount",
  limitedtime_f: "Limited Time",
  loyalty_f: "Loyalty",
  meal_deal_f: "Meal Deal",
  online_only_f: "Online Only",
  show_math_f: "Show Math",
  sugar_tax_f: "Sugar Tax",
  split: "Split",
  sponsored_f: "Sponsored",
  qr_code_f: "QR Code"
};
const PRODUCT_KEY_MAP = {
  liner_f: "Liner",
  limit: "Limit",
  variant: "Variant",
  origin: "Origin"
};
const OFFER_KEY_MAP = {
  limit: "Limit",
  liner_f: "Liner",
  min_qty_dollar_f: "Minimum Qualifier Amount",
  min_qty: "Minimum Qualifier",
  mix_and_match_f: "Mix and match",
  qty_off_total_purchase_f: "Apply to total purchase",
  reg_price: "Regular Price",
  reg_price2_d: "Regular Price 2",
};


export const AdblockInfo = ({ className, isLoading, data, circularId, pageId }) => {
  const location = useLocation();
  const [resetAdblockLoading, setResetAdblockLoading] = useState(false);
  const [copyLoading, setCopyLoading] = useState(false);
  const viewSectionModal = useModal();

  const handleResetAdblock = async(data) => {
    setResetAdblockLoading(true);
    const result = await resetAdblock(data?.uuid);
    if (result.success) {
      setResetAdblockLoading(false);
      //reload page
      navigate(0);
    } else {
      //TODO: failed to delete adblock
      setResetAdblockLoading(false);
    }

  }

  const handleParentCopy = async(data) => {

    console.log('handleParentCopy', data)
    setCopyLoading(true);
    const result = await copyAllData(data?.id);
    if (result.success) {
      setCopyLoading(false);
      //reload page
      navigate(0);
    } else {
      //TODO: failed to delete adblock
      setCopyLoading(false);
    }

  }

  return (
    <Container className={className}>
      <Header>
        <span>ADBLOCK -&nbsp;</span>
        {data && Object.keys(data)?.length > 0 && (
          <>
            <Info>
              {data?.products?.length} Products / {data?.offers?.length} Offers /{" "}
              {data?.incentive_text ? splitIncentives(data?.incentive_text).length : 0}{" "}
              Incentives
            </Info>
            {data?.sections}
            
            {data?.section !== null ? (
              <Status style={{cursor: "pointer"}} info={true} onClick={viewSectionModal.openModal}>
                SECTION
              </Status>
            ):null}

            {data?.group !== null ? (
              <Status info={true}>
                GROUP
              </Status>              
            ): null} 

            {data?.dupe_products && data?.dupe_offers ? (
              <Status >
                DUPLICATE
              </Status>
            ) : data?.dupe_products ? (
              <Status>
                PRODUCT DUPLICATE
              </Status>              
            ) : data?.dupe_offers ? (
              <Status>
                OFFERS DUPLICATE
              </Status>              
            ) : null} 

            <AdblockStatus
              approved={(data?.products_approved && data?.offers_approved) || (data?.products_approved && data?.incentive_text)}
              rejected={data?.rejected || (data?.dupe_of === null && data?.is_dupe === true)}
              noauto={data?.is_dupe || data?.section !== null || data?.group !== null}
            >
              {(data?.products_approved && data?.offers_approved) || (data?.products_approved && data?.incentive_text)
                ? "APPROVED"
                : data?.do_not_enter_f
                ? "NO ENTRY"
                : data?.skipped
                ? "SKIPPED"
                : data?.rejected
                ? "REJECTED"
                : !data?.products_approved &&
                  !data?.offers_approved &&
                  !data?.incentive_text
                ? "UNSTARTED"
                : "INCOMPLETE"}
            </AdblockStatus>

            <MenuDropDown
                className="ml-auto"
                title="ACTIONS"
                onParentCopy={handleParentCopy}
                onReset={handleResetAdblock}
                data={data}
                disabled={resetAdblockLoading || copyLoading}
              />

          </>
        )}
      </Header>
      <Content>
        {isLoading ? (
          <div style={{
            margin: 'auto'
          }}>
            <ClipLoader color="#606060" />
          </div>
        ) : data?.is_dupe ? (
          <div>
              <div>
                  { data?.dupe_adblock_uuid === null ? (
                    <Row>
                      <div>Duplicate Error</div>
                      <div>Can not find parent adblock</div>
                    </Row>
                  ) : (
                    <Row>
                      <div>Duplicate Adblock</div>
                      <div><Link target="_blank" href={location.origin + "/product/" + data?.dupe_circular_uuid + "/" + data?.dupe_page_uuid + "/" + data?.dupe_adblock_uuid}>Click To View Parent</Link></div>
                    </Row>
                  ) }
                  <Row>
                    <div>Duplicate Type</div>
                    <div>{data?.dupe_type}</div>
                  </Row>
                  { data?.dupe_type == null && (
                    <Row>
                      <div>CM by</div>
                      <div>{data?.product_approved_username || data?.offer_approved_username}</div>
                    </Row>
                  )}
                {data?.do_not_enter_f && (
                  <Row>
                    <div>DNE Marked By</div>
                    <div>{data?.adblock_user_name}</div>
                  </Row>
                )}

                {data?.products_approved && <Row>
                  <div>
                    PRODUCT APPROVED BY
                  </div>
                  <div>{data?.product_approved_username ? data?.product_approved_username : 'Username could not be determined'}</div>
                </Row>}
                
                {data?.products_approved ?
                  (data?.product_finished_at ? (<Row>
                    <div>
                      TTB PRODUCT APPROVED AT
                    </div>
                    <div>
                      {formatDateTime(data?.product_finished_at)}
                    </div>
                  </Row>) :

                    (data?.ltr_product_finished_at && <Row>
                      <div>
                        LTR PRODUCT APPROVED AT
                      </div>
                      <div>
                        {formatDateTime(data?.ltr_product_finished_at)}
                      </div>
                    </Row>)) : null}


                {data?.products.map((product, index) => (
                  <RowContainer key={`${index}key`}>
                    {(data?.products_approved || data?.rejected) &&
                      <Row>
                        <div>
                          PRODUCT {index + 1} {
                            data?.products_approved ? `ADDED BY`
                              : data?.rejected ? `REJECTED BY` : ''}
                        </div>
                        <div>{product?.username ? product?.username : 'Username could not be determined'}</div>
                        {/* <div className="pl-1">{data?.ltr_product_finished_at ? `(LTR)` : `(TTB)`}</div> */}
                        <div className="text-right pr-2">
                          {formatDateTime(product?.created_at)}
                        </div>
                      </Row>
                    }
                    <Row>
                      <div>PRODUCT {index + 1}</div>
                      <div>{product?.brand?.brand_name}</div>
                    </Row>
                    {Object.keys(PRODUCT_KEY_MAP)
                      .filter((key) => product[key])
                      .map((key) => (
                        <Row className="ml-4" key={key}>
                          {key === 'variant' ?
                            <>
                              <div>{PRODUCT_KEY_MAP[key]}</div>
                              <div>{product[key].variety_nm}</div>
                            </>
                            : key === 'origin' ?
                              <>
                                <div>{PRODUCT_KEY_MAP[key]}</div>
                                <div>{product[key].origin_nm}</div>
                              </>
                              :
                              <>
                                <div>{PRODUCT_KEY_MAP[key]}</div>
                                <div>{product[key] === true ? "TRUE" : product[key]}</div>
                              </>
                          }
                        </Row>
                      ))}
                  </RowContainer>
                ))}
                {data?.offers_approved && <Row>
                  <div>
                    OFFER APPROVED BY
                  </div>
                  <div>{data?.offer_approved_username ? data?.offer_approved_username : 'Username could not be determined'}</div>
                </Row>}

                {data?.offers_approved ?
                  data?.offer_finished_at ? (<Row>
                    <div>
                      TTB OFFER APPROVED AT
                    </div>
                    <div>
                      {formatDateTime(data?.offer_finished_at)}
                    </div>
                  </Row>) :
                    (data?.ltr_offer_finished_at && <Row>
                      <div>
                        LTR OFFER APPROVED AT
                      </div>
                      <div>
                        {formatDateTime(data?.ltr_offer_finished_at)}
                      </div>
                    </Row>) : null}

                {data?.offers.map((offer, index) => (
                  <div key={`${index}key`}>
                    {(data?.offers_approved || data?.rejected) &&
                      <Row>
                        <div>
                          OFFER {index + 1} {
                            data?.offers_approved ? `ADDED BY`
                              : data?.rejected ? `REJECTED BY` : ''}
                        </div>
                        <div>{offer?.username ? offer?.username : 'Username could not be determined'}</div>
                        {/* <div className="pl-1">{data?.ltr_offer_finished_at ? `(LTR)` : `(TTB)`}</div> */}
                        <div className="text-right pr-2">
                          {formatDateTime(offer?.created_at)}
                        </div>
                      </Row>
                    }
                    <Row>
                  <div>OFFER {index + 1}</div>
                  <div>{offer?.offer_text}</div>
                </Row>
                {Object.keys(OFFER_KEY_MAP)
                  .filter((key) => offer[key])
                  .map((key) => (
                    <Row className="ml-4" key={key}>
                      <div>{OFFER_KEY_MAP[key]}</div>
                      <div>{offer[key] === true ? "TRUE" : offer[key]}</div>
                    </Row>
                  ))}
              </div>
            ))}
            {data?.incentive_text &&
              splitIncentives(data?.incentive_text).map((incentive, index) => (
                <Row key={`${index}key`}>
                  <div>INCENTIVE {index + 1}</div>
                  <div>{incentive}</div>
                </Row>
              ))}
            {Object.keys(KEY_MAP)
              .filter((key) => data[key])
              .map((key) => (
                <Row key={key}>
                  <div>{KEY_MAP[key]}</div>
                  <div>TRUE</div>
                </Row>
              ))}

              </div>
          </div>
          ) : data?.skipped ? (
            <div className="d-flex">
              <div className="my-4 mx-auto">No data available</div>
            </div>
          ) : data?.do_not_enter_f ? (
            <Row>
              <div>DNE Marked By</div>
              <div>{data?.adblock_user_name}</div>
            </Row>
          )
        : data && Object.keys(data).length > 0 ? (
          <>
                  {data?.products_approved && <Row>
                    <div>
                      PRODUCT APPROVED BY
                    </div>
                    <div>{data?.product_approved_username ? data?.product_approved_username : 'Username could not be determined'}</div>
                  </Row>}

                  {data?.products_approved ?
                    (data?.product_finished_at ? (<Row>
                      <div>
                        TTB PRODUCT APPROVED AT
                      </div>
                      <div>
                        {formatDateTime(data?.product_finished_at)}
                      </div>
                    </Row>) :

                      (data?.ltr_product_finished_at && <Row>
                        <div>
                          LTR PRODUCT APPROVED AT
                        </div>
                        <div>
                          {formatDateTime(data?.ltr_product_finished_at)}
                        </div>
                      </Row>)) : null}

                  {data?.products?.map((product, index) => (
                    <div key={`${index}key`}>
                      {(data?.products_approved || data?.rejected) &&
                        <Row>
                          <div>
                            PRODUCT {index + 1} {
                              data?.products_approved ? `ADDED BY`
                                : data?.rejected ? `REJECTED BY` : ''}
                          </div>
                          <div>{product?.username ? product?.username : 'Username could not be determined'}</div>
                          {/* <div className="pl-1">{data?.ltr_product_finished_at ? `(LTR)` : `(TTB)`}</div> */}
                          <div className="text-right pr-2">
                            {formatDateTime(product?.created_at)}
                          </div>
                        </Row>
                      }
                      <Row>
                        <div>PRODUCT {index + 1}</div>
                        <div>{product?.brand?.brand_name}</div>
                      </Row>
                      {Object.keys(PRODUCT_KEY_MAP)
                        .filter((key) => product[key])
                        .map((key) => (
                          <Row className="ml-4" key={key}>
                            {key === 'variant' ?
                              <>
                                <div>{PRODUCT_KEY_MAP[key]}</div>
                                <div>{product[key].variety_nm}</div>
                              </>
                              : key === 'origin' ?
                                <>
                                  <div>{PRODUCT_KEY_MAP[key]}</div>
                                  <div>{product[key].origin_nm}</div>
                                </>
                                :
                                <>
                                  <div>{PRODUCT_KEY_MAP[key]}</div>
                                  <div>{product[key] === true ? "TRUE" : product[key]}</div>
                                </>
                            }
                          </Row>
                        ))}
                    </div>
                  ))}
                  
                  {data?.offers_approved && <Row>
                    <div>
                      OFFER APPROVED BY
                    </div>
                    <div>{data?.offer_approved_username ? data?.offer_approved_username : 'Username could not be determined'}</div>
                  </Row>}

                  {data?.offers_approved ?
                    data?.offer_finished_at ? (<Row>
                      <div>
                        TTB OFFER APPROVED AT
                      </div>
                      <div>
                        {formatDateTime(data?.offer_finished_at)}
                      </div>
                    </Row>) :
                      (data?.ltr_offer_finished_at && <Row>
                        <div>
                          LTR OFFER APPROVED AT
                        </div>
                        <div>
                          {formatDateTime(data?.ltr_offer_finished_at)}
                        </div>
                      </Row>) : null}

                  {data?.offers?.map((offer, index) => (
                    <div key={`${index}key`}>
                      {(data?.offers_approved || data?.rejected) &&
                        <Row>
                          <div>
                            OFFER {index + 1} {
                              data?.offers_approved ? `ADDED BY`
                                : data?.rejected ? `REJECTED BY` : ''}
                          </div>
                          <div>{offer?.username ? offer?.username : 'Username could not be determined'}</div>
                          {/* <div className="pl-1">{data?.ltr_offer_finished_at ? `(LTR)` : `(TTB)`}</div> */}
                          <div className="text-right pr-2">
                            {formatDateTime(offer?.created_at)}
                          </div>
                        </Row>
                }
                <Row>
                  <div>OFFER {index + 1}</div>
                  <div>{offer?.offer_text}</div>
                </Row>
                {Object.keys(OFFER_KEY_MAP)
                  .filter((key) => offer[key])
                  .map((key) => (
                    <Row className="ml-4" key={key}>
                      <div>{OFFER_KEY_MAP[key]}</div>
                      <div>{offer[key] === true ? "TRUE" : offer[key]}</div>
                    </Row>
                  ))}
              </div>
            ))}
            {data?.incentive_text &&
              splitIncentives(data?.incentive_text).map((incentive, index) => (
                <Row key={`${index}key`}>
                  <div>INCENTIVE {index + 1}</div>
                  <div>{incentive}</div>
                </Row>
              ))}
            {Object.keys(KEY_MAP)
              .filter((key) => data[key])
              .map((key) => (
                <Row key={key}>
                  <div>{KEY_MAP[key]}</div>
                  <div>TRUE</div>
                </Row>
              ))}
          </>
        ) : (
          <div className="d-flex">
            <div className="my-4 mx-auto">No data available</div>
          </div>
        )}
      </Content>
      <ViewSectionModal 
        {...viewSectionModal}
        sectionData={data?.section}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 2px 2px 0 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const Header = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  display: flex;
  button {
    font-size:14px;
    height:22px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: 100%;
  color:white;
`;

const Row = styled.div`
  height: 28px;
  line-height: 28px;
  display: flex;
  color: white;
  font-size: 14px;
  & > div:first-child {
    width: 240px;
    text-align: left;
    padding-left: 8px;
  }
  & > div:last-child {
    padding-left: 8px;
    flex: 1;
    text-align: left;
    border-left: 2px solid ${(props) => props.theme.palette.backgrounds};
  }
  &:nth-child(2n) {
    background: ${(props) => props.theme.palette.backgrounds};
  }
  &:nth-child(2n + 1) {
    background: ${(props) => props.theme.palette.items};
  }
`;

const Info = styled.div`
  font-size: 12px;
  margin-top: auto;
  margin-bottom: 11px;
`;

const Status = styled.div`
  margin-left: 5px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 12px;
  height: 22px;
  padding: 5px 8px;
  background: ${(props) =>
    props.approved
      ? props.theme.palette.success
      : props.rejected
      ? props.theme.palette.error
      : props.info
      ? props.theme.palette.info
      : props.theme.palette.warning};
  color: ${(props) =>
    props.rejected
      ? props.theme.palette.white
      : props.theme.palette.background};
`;

const AdblockStatus = styled.div`
  margin-left:5px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 12px;
  height: 22px;
  padding: 5px 8px;
  background: ${(props) =>
    props.approved
      ? props.theme.palette.success
      : props.rejected
      ? props.theme.palette.error
      : props.info
      ? props.theme.palette.info
      : props.theme.palette.warning};
  color: ${(props) =>
    props.rejected
      ? props.theme.palette.white
      : props.theme.palette.background};
`;


const Link = styled.a`
  font-size: 14px;
  color: white;
  text-decoration: underline;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    color: #006280;
    cursor: pointer;
  }
`;

const RowContainer = styled.div`
  border-top:2px solid ${(props) => props.theme.palette.backgrounds};
  border-bottom:2px solid ${(props) => props.theme.palette.backgrounds};
`;
