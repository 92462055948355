import { getCurrentUserAPI } from "api";
import _ from "lodash";
import React, { useEffect } from "react";
import { FaBarcode, FaCropAlt, FaTag } from "react-icons/fa";
import { FiRefreshCcw } from "react-icons/fi";
import styled from "styled-components";
import { useMainContext } from "contexts"
import { useModal } from "hooks";
import { ReportJIRAIssue } from "modals";

export const Footer = () => {
  const { liveCounterData, setLiveCounterData } = useMainContext();
  const viewReportIssueModal = useModal();

  useEffect(() => {
    handleLiveData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleLiveData = async () => {
    const res = await getCurrentUserAPI();
    if (res.success) {
      setLiveCounterData(res.results);
    }
  }
  return (
    <Container>
      <ReportJIRAIssue
        {...viewReportIssueModal}
      />
      <LeftFooter>
        <div className="my-auto">ADDISON by Deep.ad</div>
        <Link
          href="https://www.deep.ad/privacy-policy"
          target="_blank"
          className="ml-3 my-auto"
        >
          Privacy Policy
        </Link>
        <Link
          href="https://www.deep.ad/tos"
          target="_blank"
          className="ml-3 my-auto"
        >
          Terms of Service
        </Link>
        {/* <Link
          href="https://numerator.atlassian.net/secure/CreateIssue.jspa?issuetype=1&pid=12914"
          target="_blank"
          className="ml-3 my-auto"
        >
          Report Bug
        </Link>
        <Link
          href="https://numerator.atlassian.net/secure/CreateIssue.jspa?issuetype=3&pid=12914"
          target="_blank"
          className="ml-3 my-auto"
        >
          Feedback
        </Link> */}
        <Link
          className="ml-3 my-auto"
          onClick={viewReportIssueModal.openModal}
        >
          Raise an Issue
        </Link>
      </LeftFooter>
      {!_.isEmpty(liveCounterData) &&
        <LiveCounter>
          <LiveCounterItem style={{
            width: '100px',
            marginRight: '-10px'
          }}>
            <RefreshButton onClick={() => handleLiveData()}>
              <FiRefreshCcw />
            </RefreshButton>
            <FaCropAlt /> {liveCounterData.cropping_completed ? liveCounterData.cropping_completed : 0} |
          </LiveCounterItem>
          <LiveCounterItem style={{
            width: '80px',
          }}>
            CM {liveCounterData.cm_block ? liveCounterData.cm_block : 0}
          </LiveCounterItem> |
          <LiveCounterItem>
            IM
            <FaBarcode /> {liveCounterData.im_product ? liveCounterData.im_product : 0}
            <FaTag /> {liveCounterData.im_offer ? liveCounterData.im_offer : 0}
          </LiveCounterItem> |
          <LiveCounterItem>
            EM
            <FaBarcode /> {liveCounterData.em_product ? liveCounterData.em_product : 0}
            <FaTag /> {liveCounterData.em_offer ? liveCounterData.em_offer : 0}
          </LiveCounterItem> |
          <LiveCounterItem>
            TTB
            <FaBarcode /> {liveCounterData.ttb_product_completed ? liveCounterData.ttb_product_completed : 0}
            <FaTag /> {liveCounterData.ttb_offer_completed ? liveCounterData.ttb_offer_completed : 0}
          </LiveCounterItem> |
          <LiveCounterItem>LTR
            <FaBarcode /> {liveCounterData.ltr_product_completed ? liveCounterData.ltr_product_completed : 0}
            <FaTag /> {liveCounterData.ltr_offer_completed ? liveCounterData.ltr_offer_completed : 0}
          </LiveCounterItem>
        </LiveCounter>
      }
    </Container>
  );
};

const Container = styled.div`
  background: #131313;
  height: 50px;
  color: white;
  font-size: 16px;
  padding: 0 12px;
  /* display: flex; */
  border-top: 2px solid #0a0a0a;
`;
const Link = styled.a`
  font-size: 14px;
  color: white;
  text-decoration: underline;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    color: #006280;
    cursor: pointer;
  }
`;

const LiveCounter = styled.div`
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* font-size: 24px; */
`

const LeftFooter = styled.div`
  float: left;
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  margin-top: 10px;
`

const LiveCounterItem = styled.div`
  /* padding-top: 10px; */
  width: 150px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
`

const RefreshButton = styled.span`
  border-radius: 50px;
  display: flex;
  padding: 5px;
  background-color: white;
  color: black;
  cursor: pointer;
  transition: 200ms;
  &:hover {
    box-shadow: 0px 1px 10px white;
  }
`