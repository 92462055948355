/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useDebounce } from "use-debounce";
import { useOutsideClick } from "hooks";
import styled from "styled-components";
import { useQuery } from "react-query";
import { AiFillCaretDown, AiOutlineClose } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import { useMainContext } from "contexts";
import "./style.scss";
import _ from "lodash";
import useKeypress from "react-use-keypress";
import { getBrandFullInfo } from "api";

export const AutoComplete = ({
  id,
  value,
  onChange,
  label,
  className,
  backgroundColor = "#eee",
  placeholder = "Search or type here",
  width,
  idField = "id",
  inputId,
  displayValue = "",
  valueProp = "name",
  data,
  func = () => { },
  onSelectId = () => { },
  onSelectValue = () => { },
  onClick = () => { },
  onFocus = () => { },
  onCreateNew = () => { },
  showAddProduct = true
}) => {
  const menuRef = useRef(null);
  const [search, setSearch] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [debouncedValue] = useDebounce(search, 500);
  const [innerValue, setInnerValue] = useState("");
  const { postPageEvents } = useMainContext();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentBrandName, setCurrentBrandName] = useState("");
  const [brandInfo, setBrandInfo] = useState({
    product_nm : "",
    brand_nm : "",
    manufacturer_nm : "",
    sub_cat_nm : ""
  });
  const [brandInfoDebouncedValue] = useDebounce(currentBrandName, 500);
  const [brandInfoLoading, setBrandInfoLoading] = useState(false);

  useOutsideClick(menuRef, clickOutsideMenu);
  function clickOutsideMenu() {
    setShowResults(false);
    setCurrentIndex(null);
  }
  const handleChange = (params) => {
    if (params !== value) {
      setSearch("");
      // `title` may not exists in params for every usage
      onChange(params[idField], params.title);
      onSelectId(params[idField]);
      setInnerValue(params[valueProp]);
      onSelectValue(params[valueProp], params);
      postPageEvents("click", params[valueProp]);
    }
    setShowResults(false);
    setCurrentIndex(null);
  };

  const clearBrandInfo = () => 
  {
    setCurrentBrandName("");
    setBrandInfo({
      product_nm : "",
      brand_nm: "",
      manufacturer_nm: "",
      sub_cat_nm: ""
    })
    setBrandInfoLoading(false);
  }

  const showBrandFullInfo = async (brand) => {
    setCurrentBrandName(brand.brand_name);
  }

  const getBrandInfo = async (brand_name) => {
    setBrandInfoLoading(true);
    const res = await getBrandFullInfo(brand_name);
    if (res.success) {
      if (res.payload) {
        setBrandInfo({
          product_nm : res.payload.brand_name,
          brand_nm: res.payload.brand_group_name,
          manufacturer_nm: res.payload?.manufacturer ? res.payload?.manufacturer.alt_nm : "",
          sub_cat_nm: res.payload?.product_category ? res.payload?.product_category.sub_cat_nm : "",
        })
      }
    }
    setBrandInfoLoading(false);
  }

  const { data: queryData, isLoading, isFetching } = useQuery(
    [debouncedValue],
    () => func(debouncedValue),
    {
      keepPreviousData: true,
      enabled: data === undefined && debouncedValue.length > 0,
    }
  );

  const [focused, setFocused] = useState(false);

  const handleBlur = (event) => {
    setFocused(false);
    onFocus(false);
    postPageEvents("blur", id || `${label} Dropdown`);
    clearBrandInfo();
  };

  const handleClear = () => {
    onChange("");
    setSearch("");
    onSelectId("");
    setInnerValue("");
    onSelectValue("");
    setCurrentIndex(null);
    clearBrandInfo();
  };

  useEffect(() => {
    if (debouncedValue) {
    } else {
      setShowResults(false);
    }
    return () => { };
  }, [debouncedValue]);

  useEffect(() => {
    if (brandInfoDebouncedValue) {
      getBrandInfo(brandInfoDebouncedValue);
    }
  }, [brandInfoDebouncedValue]);

  useEffect(() => {
    if (displayValue) {
      setInnerValue(displayValue);
    }
    return () => { };
  }, [displayValue]);

  useEffect(() => {
    if (!value) {
      setInnerValue("");
      setSearch("");
    }
    return () => { };
  }, [value]);

  useEffect(() => {
    // if ((queryData?.results && queryData.results.length > 0) || (data && data.length > 0)) {
    if ((queryData?.results && queryData.results.length > 0)) {
      console.log('userFEffect', queryData, data);
      setShowResults(true);
    }
    return () => { };
  }, [queryData?.results, data]);

  
  useKeypress(["ArrowUp"], (event) => {
    if (focused && showResults && results)
    { 
      event.preventDefault();
      if(currentIndex === null)
      {
        showBrandFullInfo(results[0])
        setCurrentIndex(0);
      }
      else if(currentIndex > 0 && currentIndex < (results.length)) {
        showBrandFullInfo(results[currentIndex - 1])
        setCurrentIndex(currentIndex - 1);
      }
    }
  });

  useKeypress(["ArrowDown"], (event) => {
    if (focused && showResults && results) {
      event.preventDefault();
      if(currentIndex === null) {
        showBrandFullInfo(results[0])
        setCurrentIndex(0);
      }
      else if(currentIndex < (results.length - 1)) {
        showBrandFullInfo(results[currentIndex + 1])
        setCurrentIndex(currentIndex + 1); 
      }
    }
  });
  
  useKeypress(["Enter"], (event) => {
    if (focused && results) {
      event.preventDefault();
      if(showResults && currentIndex !== null && innerValue === "") {
        handleChange(results[currentIndex]);
      }
    }
    clearBrandInfo();
  });

  useEffect(() => {
    if(document.getElementsByClassName("selectedidx").length > 0){
      document.getElementsByClassName("selectedidx")[0].scrollIntoView({block: "nearest", inline: "nearest"});
    }
  }, [currentIndex]);


  const results = data ? _.isArray(data)
    ? data?.filter(item =>
      item[valueProp]?.toLowerCase().includes(debouncedValue?.toLowerCase())
    ) : [data]
    : queryData?.results;

  return (
    <div>
    <div className={className} onClick={onClick}>
      {label && <span className="select-label my-auto">{label}</span>}
      <div className="auto-complete" style={{ width }}>
        <div className="menu-button" style={{ width }}>
          {innerValue ? (
            <>
              <InnerValue className="mr-auto" value={innerValue} readOnly />
              <button
                className="menu-button__drop-down"
                onClick={() => handleClear()}
              >
                <AiOutlineClose color="white" alt="clear" />
              </button>
            </>
          ) : (
            <div className="d-flex w-100">
              <StyledInput
                type="text"
                id={inputId || Math.random(100)}
                aria-labelledby={"auto complete"}
                autocomplete="chrome-off"
                onFocus={() => {
                  setCurrentIndex(null);
                  setFocused(true);
                  onFocus(true);
                  postPageEvents("focus", id || `${label} Dropdown`);
                }}
                onBlur={handleBlur}
                aria-describedby={"auto complete"}
                value={search}
                placeholder={placeholder}
                onChange={(e) => {
                  setCurrentIndex(null);
                  setSearch(e.target.value);
                  setShowResults(true);
                  clearBrandInfo();
                }}
              />

              {isLoading || isFetching ? (
                <div className="my-auto">
                  <ClipLoader size={16} color="#fff" />
                </div>
              ) : (
                <AiFillCaretDown
                  color="white"
                  className="menu-button__drop-down"
                  alt="drop-down"
                />
              )}
            </div>
          )}

          <Underline focused={focused} />
        </div>
        {showResults && (
          <div className="menu-dropdown" ref={menuRef}>
            <div className="menu-dropdown-content">
              {results && results.length > 0 ? (
                results.map((item, index) => {
                  return (
                    <div
                      className={classNames(
                        "menu-item",
                        item[valueProp] === value ? "active" : "",            
                        index === currentIndex ? "selectedidx" : "",
                      )}
                      key={`key${index}`}
                      onClick={() => handleChange(item)}
                      onMouseEnter={() => showBrandFullInfo(item)}
                      onMouseLeave={() => clearBrandInfo()}                    
                      style={{ width }}
                    >
                      {item[valueProp]} &nbsp; &nbsp;
                      {currentBrandName === item.brand_name && brandInfoLoading &&
                      <ClipLoader size={12} color="#fff" />}
                    </div>
                  );
                })
              ) : (
                <div className="my-3 d-flex justify-content-center">
                  No results
                </div>
              )}
            </div>
            {showAddProduct &&
              <div className="create-new" onClick={() => onCreateNew(search)}>
                Add As New {label}
              </div>
            }
          </div>
        )}
      </div>
    </div>
    {currentBrandName  ? (
    <BrandInfo>
      <div>{`Product Name : ${brandInfo.product_nm}`}</div>
      <div>{`Brand Name : ${brandInfo.brand_nm}`}</div>
      <div>{`Manufacturer : ${brandInfo.manufacturer_nm}`}</div>
      <div>{`Sub Category : ${brandInfo.sub_cat_nm}`}</div>
    </BrandInfo>) : null}
    </div>
  );
};

const StyledInput = styled.input`
  background: transparent;
  color: white;
  border: none;
  height: 32px;
  line-height: 32px;
  width: calc(100% - 24px);
  font-size: 14px;
  outline: none;
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
`;

const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;

const InnerValue = styled.input`
  background: transparent;
  border: none;
  outline: none;
  color: white;
  width: 300px;
  &:focus {
    border: none;
    outline: none;
  }
`;

const BrandInfo = styled.div`
  position : absolute;
  bottom   : 70px;
  font-size: 12px;
  div {
   margin-bottom:10px;
  }
`;
