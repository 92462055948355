import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button } from "components";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import { SPECIAL_DATES } from "config";

export const InlineDateRange = ({
  startDate = "",
  endDate = "",
  onChange = () => {},
  onClear = () => {},
  className,
  open = false,
  minDate = ""
}) => {
  const [uploadDates, setUploadDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const ref = useRef();


  useEffect(() => {
    setUploadDates([
      {
        startDate: startDate ? new Date(`${startDate}`) : new Date(),
        endDate: endDate ? new Date(`${endDate}`) : new Date(),
        key: "selection",
      },
    ]);
    return () => {};
  }, [startDate, endDate]);

  const handleSelectUploadDate = (item) => {
    setUploadDates([item.selection]);
  };

  const handleOk = () => {
    let startDate = new Date(`${uploadDates[0].startDate}`).setHours(0,0,0)
    let endDate = new Date(`${uploadDates[0].endDate}`).setHours(23,59,59)
    onChange([
      format(startDate, "yyyy-MM-dd HH:mm:ss"),
      format(endDate, "yyyy-MM-dd HH:mm:ss")
    ]);
  };


  const handleSelectDate = () => {
    ref.current.focus();
  };
  const handleClear = () => {
    onClear();
  };

  return (
    <Container className={className}>
      <div className="d-flex">
        <div className="d-flex justify-content-center">
          <Label>From:</Label>
          <DateInput onClick={handleSelectDate}>
            {startDate && !SPECIAL_DATES.includes(startDate)
              ? format(new Date(`${startDate}`), "MMM dd yyyy")
              : "-"}
          </DateInput>
        </div>
        <div className="d-flex justify-content-center">
          <Label>To:</Label>
          <DateInput onClick={handleSelectDate}>
            {endDate
              ? format(new Date(`${endDate}`), "MMM dd yyyy")
              : "-"}
          </DateInput>
        </div>
      </div>
        <DateRangeContainer
          ref={ref}
        >
          <DateRangePicker
            ranges={uploadDates}
            onChange={handleSelectUploadDate}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            direction="horizontal"
            months={1}
            renderStaticRangeLabel={(range) => {
              return range.label;
            }}
            inputRanges={[]}
            minDate={minDate ? minDate : undefined}
          />
          <DateRangeButtons className="d-flex mt-1">
            <Button
              size="small"
              className="ml-auto"
              width="90px"
              onClick={handleOk}
            >
              Ok
            </Button>
            <Button
              size="small"
              className="ml-2"
              width="90px"
            >
              Cancel
            </Button>
            <Button
              size="small"
              className="ml-2"
              width="90px"
              onClick={handleClear}
            >
              Clear
            </Button>
          </DateRangeButtons>
        </DateRangeContainer>
    </Container>
  );
};

const Container = styled.div`
  .justify-content-right {
    justify-content: right;
  }
  .justify-content-center {
    padding:0 20px;
  }
`;

const Label = styled.div`
  color: white;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
`;

const DateInput = styled.div`
  width: 100px;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  border-bottom: 1px solid ${(props) => props.theme.palette.itemsHover};
  color: #e2e2e2;
  text-align: center;
`;

const DateRangeContainer = styled.div`
  width: auto;
  margin-top:20px;
  z-index: 9;
  background: white;
  .rdrDefinedRangesWrapper {
    display: none;
  }
`;
const DateRangeButtons = styled.div`
  border-top: 1px solid #e2e2e2;
  padding: 8px 12px 8px 0;
`;
